import { ITimingUpdatePayload } from "../types/timing-type";
import * as base from "./base-api-service";

/**
 * Get Timing
 */
export const getTiming: () => Promise<string> = () => {  
  return base.get("site/gettiming");
};

/**
 * Update timing
 * @param data Payload
 */
export const updateTiming: (data: ITimingUpdatePayload) => Promise<void> = (
  data: ITimingUpdatePayload
) => {
  return base.patch("site/updatetiming", data);
};
/**
 * clear timing
 */
 export const clearTiming: () => Promise<void> = () => {
  return base.del("site/deletetiming", {});
};