import { useContext, useEffect, useRef, useState } from "react";
import { Form, FormGroup, FormLabel } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { mySwal } from "../../config/sw-config";
import { appContext } from "../../context/app-context";
import { HandleException } from "../../helpers/exception-handler";
import { useAppDispatch, useAppSelector } from "../../hooks/store-hooks";
import { update } from "../../slices/collection-slice";

export default function UpdateCollection() {
  const { getImage, getVideo } = useContext(appContext);

  const { id } = useParams();
  const dispatch = useAppDispatch();
  const collection = useAppSelector((s) =>
    s.collection.collections.find((x) => x.id === +id!)
  );
  const [loading, setLoading] = useState(false);

  //#region Form Fields
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [banner, setBanner] = useState<File>();
  const [isPublished, setIsPublished] = useState(false);
  const [imgUrl, setImgUrl] = useState<string>();
  //#endregion

  //#region Input Refs
  const nameInputRef = useRef<HTMLInputElement>(null);
  const descriptionRef = useRef<HTMLTextAreaElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  //#endregion

  useEffect(() => {
    if (collection) {
      setName(collection.name);
      setDescription(collection?.description);
      setIsPublished(collection?.isPublished);
      setImgUrl(getImage!(collection.banner));
    }
  }, [collection, getImage]);

  //validate
  const validate = () => {
    if (!name) {
      mySwal.fire({
        text: "Please enter collection name.",
        toast: true,
        icon: "error",
        timer: 2000,
        timerProgressBar: true,
      });
      nameInputRef.current?.focus();
      return false;
    }

    if (!description) {
      mySwal.fire({
        text: "Please write description.",
        toast: true,
        icon: "error",
        timer: 2000,
        timerProgressBar: true,
      });
      descriptionRef.current?.focus();
      return false;
    }
    return true;
  };

  //update
  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validate()) {
      try {
        setLoading(true);
        await dispatch(
          update({
            name,
            description,
            banner: banner!,
            isPublished,
            id: collection!.id,
          })
        ).unwrap();
        mySwal.fire({
          titleText: "Updated",
          text: "Collection updated successfully.",
          icon: "success",
        });
      } catch (error) {
        HandleException(error);
      } finally {
        setLoading(false);
      }
    }
  };

  return collection ? (
    <>
      <section className="content-header">
        <Link to="../collections" className="btn btn-warning btn-flat btn-sm">
          <i className="fa fa-arrow-left"></i> Back To List
        </Link>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-md-6">
            <form onSubmit={submit}>
              <div className="card card-outline card-danger">
                <div className="card-header with-border">
                  <h4 className="card-title">Update Collection</h4>
                </div>
                <div className="card-body">
                  <FormGroup>
                    <FormLabel>Name</FormLabel>
                    <input
                      value={name}
                      ref={nameInputRef}
                      type="text"
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      className="form-control"
                    />
                  </FormGroup>

                  <FormGroup>
                    <FormLabel>Description</FormLabel>
                    <textarea
                      value={description}
                      onChange={(e) => {
                        setDescription(e.target.value);
                      }}
                      ref={descriptionRef}
                      className="form-control"
                    />
                  </FormGroup>
                  <FormGroup>
                    <img src={imgUrl} className="img-thumbnail" alt="" />
                  </FormGroup>

                  <FormGroup>
                    <FormLabel>Banner</FormLabel>
                    <input
                      ref={fileInputRef}
                      onChange={(e) => {
                        if (e.target.files?.length) {
                          setBanner(e.target.files[0]);
                          setImgUrl(URL.createObjectURL(e.target.files[0]));
                        }
                      }}
                      type="file"
                      className="form-control"
                    />
                  </FormGroup>
                </div>
                <div className="card-footer">
                  <div className="row">
                    <div className="col text-center">
                      <Form.Check
                        type="switch"
                        checked={isPublished}
                        label="Published"
                        onChange={(e) => {
                          setIsPublished(e.target.checked);
                        }}
                      />
                    </div>
                    <div className="col text-right">
                      <input
                        type="submit"
                        className="btn btn-danger btn-flat"
                        value="Update"
                      />
                    </div>
                  </div>
                </div>
                {loading ? (
                  <div className="overlay">
                    <i className="fa fa-spinner fa-spin"></i>
                  </div>
                ) : undefined}
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  ) : (
    <></>
  );
}
