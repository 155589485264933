import { IMaterialType, IMaterialTypeCreatePayload, IMaterialTypeUpdatePayload } from "../types/material-type-type";
import * as base from "./base-api-service";

/**
 * Get list of material types
 */
export const getAll: () => Promise<IMaterialType[]> = () => {  
  return base.get("materialtype/getall");
};

/**
 * Get material type by id
 * @param id MaterialType id
 * @returns MaterialType
 */
export const get: (id: number) => Promise<IMaterialType> = (id: number) => {
  return base.get("materialtype/get", {
    id,
  });
};

/**
 * Add new MaterialType
 * @param data Payload
 * @returns MaterialType
 */
export const add: (data: IMaterialTypeCreatePayload) => Promise<IMaterialType> = (
  data: IMaterialTypeCreatePayload
) => {
  return base.post("materialtype/add", data);
};

/**
 * Update MaterialType
 * @param data Payload
 * @returns MaterialType
 */
export const update: (data: IMaterialTypeUpdatePayload) => Promise<IMaterialType> = (
  data: IMaterialTypeUpdatePayload
) => {
  return base.patch("materialtype/update", data);
};

/**
 * Delete MaterialType
 * @param id MaterialType Id
 * @returns void
 */
export const del: (id: number) => Promise<void> = (id: number) => {
  return base.del("materialtype/delete", {
    id,
  });
};
