import { useState } from "react";
import { Button, Form, FormGroup, FormLabel, Modal } from "react-bootstrap";
import { mySwal } from "../../config/sw-config";
import { HandleException } from "../../helpers/exception-handler";
import { useAppDispatch } from "../../hooks/store-hooks";
import { add } from "../../slices/category-slice";

type TProps = {
  visible: boolean;
  onClose: () => void;
  onAdded?: () => void;
};

export default function AddCategory(props: TProps) {
  //form values
  const [name, setName] = useState("");
  const [thumbnail, setThumbnail] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();

  const addCategory = async () => {
    if (!name) {
      mySwal.fire({
        titleText: "",
        text: "Please enter category name.",
        timer: 2000,
        icon: "error",
      });
      return;
    }

    if (!thumbnail) {
      mySwal.fire({
        titleText: "",
        text: "Please select thumbnail.",
        timer: 2000,
        icon: "error",
      });
      return;
    }

    try {
      setLoading(true);
      await dispatch(
        add({
          name,
          thumbnail,
        })
      ).unwrap();
      mySwal.fire("Added", "Category added successfully.", "success");
      setName("");
      setThumbnail(null);
      if (props.onAdded) {
        props.onAdded();
      }
    } catch (error) {
      HandleException(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      show={props.visible}
      onHide={() => {
        if (props.onClose) {
          props.onClose();
        }
      }}
    >
      <Form
        onSubmit={(e) => {
          addCategory();
          e.preventDefault();
        }}
      >
        <Modal.Header closeButton closeLabel="hello">
          <Modal.Title>Add New Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            <FormLabel>Category Name</FormLabel>
            <input
              value={name}
              onChange={(e) => setName(e.target.value)}
              autoFocus
              type="text"
              className="form-control"
            />
          </FormGroup>
          <FormGroup className="mt-2">
            <FormLabel>Select Thumbnail</FormLabel>
            <input
              type="file"
              onChange={(e) => {
                if (e.target?.files?.length) {
                  setThumbnail(e.target?.files[0]);
                }
              }}
              className="form-control"
              accept="image/*"
            />
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={loading}
            type="submit"
            variant="primary"
            className="btn-flat"
            onClick={() => {}}
          >
            {loading ? "Please wait..." : "Save"}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
