import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { HandleException } from "../../helpers/exception-handler";
import { useAppDispatch, useAppSelector } from "../../hooks/store-hooks";
import { fetch as fetchCategories } from "../../slices/category-slice";
import { fetch as fetchColors } from "../../slices/color-slice";
import { fetch as fetchFinishTypes } from "../../slices/finish-type-slice";
import { fetch as fetchMaterialTypes } from "../../slices/material-type-slice";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Form } from "react-bootstrap";
import convertToHtml from "draftjs-to-html";
import { mySwal } from "../../config/sw-config";
import { add } from "../../slices/product-slice";

export default function AddProduct() {
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const finishTypes = useAppSelector((s) => s.finishType.finishTypes);
  const colors = useAppSelector((s) => s.color.colors);
  const categories = useAppSelector((s) => s.category.categories);
  const materials = useAppSelector((s) => s.materialType.materialTypes);

  //#region form fields
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [description, setDescription] = useState(() =>
    EditorState.createEmpty()
  );
  const [finishId, setFinishId] = useState("");
  const [size, setSize] = useState("");
  const [materialId, setMaterialId] = useState("");
  const [hsnCode, setHsnCode] = useState("");
  const [cbm, setCbm] = useState("");
  const [price, setPrice] = useState("");
  const [grossWeight, setGrossWeight] = useState("");
  const [netWeight, setNetWeight] = useState("");
  const [remark, setRemark] = useState(() => EditorState.createEmpty());
  const [isPublic, settIsPublic] = useState(false);
  const [categoryId, setCategoryId] = useState("");
  const [newBadge, setNewBadge] = useState(false);
  const [colorId, setColorId] = useState("");
  //#endregion

  //#region form input refs
  const nameInputRef = useRef<HTMLInputElement>(null);
  const codeInputRef = useRef<HTMLInputElement>(null);
  const descriptionInputRef = useRef<Editor>(null);
  const finishInputRef = useRef<HTMLSelectElement>(null);
  const sizeInputRef = useRef<HTMLInputElement>(null);
  const materialInputRef = useRef<HTMLSelectElement>(null);
  const hsnInputRef = useRef<HTMLInputElement>(null);
  const cbmInputRef = useRef<HTMLInputElement>(null);
  const priceInputRef = useRef<HTMLInputElement>(null);
  const grossWeightInputRef = useRef<HTMLInputElement>(null);
  const netWeightInputRef = useRef<HTMLInputElement>(null);
  const remarkInputRef = useRef<Editor>(null);
  const categoryInputRef = useRef<HTMLSelectElement>(null);
  const colorInputRef = useRef<HTMLSelectElement>(null);
  //#endregion

  //load values
  useEffect(() => {
    try {
      dispatch(fetchCategories()).unwrap();
      dispatch(fetchColors()).unwrap();
      dispatch(fetchFinishTypes()).unwrap();
      dispatch(fetchMaterialTypes()).unwrap();
    } catch (error) {
      HandleException(error);
    }
  }, [dispatch]);

  //submit form
  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isValid()) {
      setLoading(true);
      try {
        await dispatch(
          add({
            name,
            code,
            description: convertToHtml(
              convertToRaw(description.getCurrentContent())
            ),
            remark: convertToHtml(convertToRaw(remark.getCurrentContent())),
            finishId: +finishId!,
            size,
            materialId: +materialId!,
            hsnCode,
            cbm: +cbm!,
            price: price ? +price! : undefined,
            grossWeight:grossWeight  ? +grossWeight! : undefined,
            netWeight: netWeight  ? +netWeight! : undefined,
            isPublic,
            categoryId: +categoryId!,
            newBadge,
            colorId: colorId ? +colorId! : undefined,
          })
        ).unwrap();
        mySwal.fire({
          titleText: "Added",
          text: "Product Added.",
          icon: "success",
          timer: 2000,
          toast: true,
        });
        reset();
      } catch (error) {
        HandleException(error);
      } finally {
        setLoading(false);
      }
    }
  };

  //reset form
  const reset = () => {
    setCode("");
    setName("");
    setDescription(EditorState.createEmpty());
    setFinishId("");
    setSize("");
    setMaterialId("");
    setHsnCode("");
    setCbm("");
    setPrice("");
    setGrossWeight("");
    setNetWeight("");
    setRemark(EditorState.createEmpty());
    settIsPublic(false);
    setNewBadge(false);
    setCategoryId("");
    setColorId("");
    nameInputRef.current?.focus();
  };

  //validate form
  const isValid: () => boolean = () => {
    if (!name) {
      mySwal.fire({
        text: "Please enter product name.",
        toast: true,
        icon: "error",
        timer: 2000,
        timerProgressBar: true,
      });
      nameInputRef.current?.focus();
      return false;
    }

    if (!code) {
      mySwal.fire({
        text: "Please enter product code.",
        toast: true,
        icon: "error",
        focusConfirm: true,
        timer: 2000,
        timerProgressBar: true,
      });
      codeInputRef.current?.focus();
      return false;
    }

    if (!categoryId) {
      mySwal.fire({
        text: "Please select category.",
        toast: true,
        icon: "error",
        timer: 2000,
        timerProgressBar: true,
      });
      categoryInputRef.current?.focus();
      return false;
    }

    if (!finishId) {
      mySwal.fire({
        text: "Please select finish.",
        toast: true,
        icon: "error",
        timer: 2000,
        timerProgressBar: true,
      });
      finishInputRef.current?.focus();
      return false;
    }

    if (!materialId) {
      mySwal.fire({
        text: "Please select material.",
        toast: true,
        icon: "error",
        timer: 2000,
        timerProgressBar: true,
      });
      materialInputRef.current?.focus();
      return false;
    }

    if (!size) {
      mySwal.fire({
        text: "Please enter product size.",
        toast: true,
        icon: "error",
        timer: 2000,
        timerProgressBar: true,
      });
      sizeInputRef.current?.focus();
      return false;
    }

    if (!cbm) {
      mySwal.fire({
        text: "Please enter cbm.",
        toast: true,
        icon: "error",
        timer: 2000,
        timerProgressBar: true,
      });
      cbmInputRef.current?.focus();
      return false;
    }

    // if (!grossWeight) {
    //   mySwal.fire({
    //     text: "Please enter gross weight.",
    //     toast: true,
    //     icon: "error",
    //     timer: 2000,
    //     timerProgressBar: true,
    //   });
    //   grossWeightInputRef.current?.focus();
    //   return false;
    // }

    // if (!netWeight) {
    //   mySwal.fire({
    //     text: "Please enter net weight.",
    //     toast: true,
    //     icon: "error",
    //     timer: 2000,
    //     timerProgressBar: true,
    //   });
    //   netWeightInputRef.current?.focus();
    //   return false;
    // }

    return true;
  };

  return (
    <>
      <section className="content-header">
        <NavLink to="../products" className="btn btn-flat btn-sm btn-warning">
          <i className="fa fa-arrow-left"></i> Back To List
        </NavLink>
      </section>
      <section className="content">
        <form onSubmit={submit}>
          <div className="card card-outline card-default">
            <div className="card-header with-border">
              <h4 className="card-title">Add New Product</h4>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-sm-6 col-md-4">
                  <div className="form-group">
                    <label>Name</label>
                    <input
                      ref={nameInputRef}
                      type="text"
                      className="form-control"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-md-4">
                  <div className="form-group">
                    <label>Product Code</label>
                    <input
                      ref={codeInputRef}
                      type="text"
                      className="form-control"
                      value={code}
                      onChange={(e) => {
                        setCode(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="col-sm-6 col-md-4">
                  <div className="form-group">
                    <label>HSN Code</label>
                    <input
                      ref={hsnInputRef}
                      type="text"
                      className="form-control"
                      value={hsnCode}
                      onChange={(e) => {
                        setHsnCode(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="col-sm-6 col-md-4">
                  <div className="form-group">
                    <label>Category</label>
                    <select
                      ref={categoryInputRef}
                      value={categoryId}
                      onChange={(e) => {
                        setCategoryId(e.target.value);
                      }}
                      className="form-control"
                    >
                      <option value={undefined}>--- Select Category---</option>
                      {categories.map((cat, i) => (
                        <option key={i} value={cat.id}>
                          {cat.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="col-sm-6 col-md-4">
                  <div className="form-group">
                    <label>Finish</label>
                    <select
                      value={finishId}
                      ref={finishInputRef}
                      onChange={(e) => {
                        setFinishId(e.target.value);
                      }}
                      className="form-control"
                    >
                      <option value="">--- Select Finish---</option>
                      {finishTypes.map((finish, i) => (
                        <option key={i} value={finish.id}>
                          {finish.finish}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="col-sm-6 col-md-4">
                  <div className="form-group">
                    <label>Material</label>
                    <select
                      ref={materialInputRef}
                      value={materialId}
                      onChange={(e) => {
                        setMaterialId(e.target.value);
                      }}
                      className="form-control"
                    >
                      <option value={undefined}>
                        --- Select Material---
                      </option>
                      {materials.map((material, i) => (
                        <option key={i} value={material.id}>
                          {material.material}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                {/*<div className="col-sm-6 col-md-4">
                  <div className="form-group">
                    <label>Color</label>
                    <select
                      value={colorId}
                      ref={colorInputRef}
                      onChange={(e) => {
                        setColorId(e.target.value);
                      }}
                      className="form-control"
                    >
                      <option value={undefined}>--- Select Color ---</option>
                      {colors.map((color, i) => (
                        <option key={i} value={color.id}>
                          {color.colorName}
                        </option>
                      ))}
                    </select>
                  </div>
                      </div>*/}

                <div className="col-sm-6 col-md-4">
                  <div className="form-group">
                    <label>Size</label>
                    <input
                      ref={sizeInputRef}
                      type="text"
                      className="form-control"
                      value={size}
                      onChange={(e) => {
                        setSize(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="col-sm-6 col-md-4">
                  <div className="form-group">
                    <label>CBM</label>
                    <input
                      ref={cbmInputRef}
                      type="number"
                      className="form-control"
                      value={cbm}
                      onChange={(e) => {
                        setCbm(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="col-sm-6 col-md-4">
                  <div className="form-group">
                    <label>Gross Weight</label>
                    <input
                      ref={grossWeightInputRef}
                      type="number"
                      className="form-control"
                      value={grossWeight}
                      onChange={(e) => {
                        setGrossWeight(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="col-sm-6 col-md-4">
                  <div className="form-group">
                    <label>Net Weight</label>
                    <input
                      ref={netWeightInputRef}
                      type="number"
                      className="form-control"
                      value={netWeight}
                      onChange={(e) => {
                        setNetWeight(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="col-sm-6 col-md-4">
                  <div className="form-group">
                    <label>Price</label>
                    <input
                      ref={priceInputRef}
                      type="number"
                      className="form-control"
                      value={price}
                      onChange={(e) => {
                        setPrice(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label>Description</label>
                    <Editor
                      ref={descriptionInputRef}
                      editorState={description}
                      onEditorStateChange={(s) => {
                        setDescription(s);
                      }}
                      placeholder="Write something!"
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label>Remark</label>
                    <Editor
                      ref={remarkInputRef}
                      editorState={remark}
                      onEditorStateChange={(s) => {
                        setRemark(s);
                      }}
                      placeholder="Write something!"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="card-footer text-right">
              <div className="row">
                <div className="col">
                  <Form.Check
                    className="d-inline"
                    type="switch"
                    label="Public"
                    checked={isPublic}
                    onChange={(e) => {
                      settIsPublic(e.target.checked);
                    }}
                  />
                </div>
                <div className="col">
                  <Form.Check
                    checked={newBadge}
                    className="d-inline"
                    type="switch"
                    label="New Badge"
                    onChange={(e) => {
                      setNewBadge(e.target.checked);
                    }}
                  />
                </div>
                <div className="col">
                  <input
                    type="submit"
                    className="btn btn-default btn-flat"
                    value="Save"
                  />
                </div>
              </div>
            </div>
            {loading ? (
              <div className="overlay">
                <i className="fa fa-spinner fa-spin"></i>
              </div>
            ) : undefined}
          </div>
        </form>
      </section>
    </>
  );
}
