import { useEffect, useMemo, useState } from "react";
import { Button, Form, FormGroup, Table } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { remove, updateFlags } from "../../slices/product-slice";
import { HandleException } from "../../helpers/exception-handler";
import { useAppDispatch, useAppSelector } from "../../hooks/store-hooks";
import { fetch } from "../../slices/product-slice";
import { mySwal } from "../../config/sw-config";
import MRT, { MRT_ColumnDef } from "material-react-table";
import { IProduct } from "../../types/product-type";

export default function Products() {
    const navigate = useNavigate();
    const products = useAppSelector((s) => s.product.products);
    const dispatch = useAppDispatch();

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        async function loadProducts() {
            try {
                await dispatch(fetch()).unwrap();
            } catch (error) {
                HandleException(error);
            } finally {
                setLoading(false);
            }
        }
        loadProducts();
    }, [dispatch]);

    const deleteProduct = (id: number) => {
        mySwal
            .fire({
                titleText: "Confirm",
                text: "Are you sure?",
                confirmButtonText: "Yes, Delete!",
                icon: "warning",
                showCancelButton: true,
            })
            .then(async (res) => {
                if (res.isConfirmed) {
                    try {
                        await dispatch(remove(id)).unwrap();
                    } catch (error) {
                        HandleException(error);
                    }
                }
            });
    };

    //table columns
    const columns: MRT_ColumnDef<IProduct>[] = useMemo(
        () =>
            [
                {
                    accessorKey: "name",
                    header: "Name",
                },
                {
                    accessorKey: "code",
                    header: "Code",
                },
                {
                    accessorKey: "hsnCode",
                    header: "HSN",
                },
                {
                    accessorKey: "finish.finish",
                    header: "Finish",
                },
                {
                    accessorKey: "material.material",
                    header: "Material",
                },
                {
                    accessorKey: "cbm",
                    header: "CBM",
                },
                {
                    accessorKey: "isPublic",
                    header: "Public",
                    Cell: (props) => (
                        <Form.Check
                            type="switch"
                            defaultChecked={props.row.original.isPublic}
                            onChange={async (e) => {
                                try {
                                    await dispatch(
                                        updateFlags({
                                            id: props.row.original.id,
                                            isPublic: e.target.checked,
                                        })
                                    ).unwrap();
                                } catch (error) {
                                    e.target.checked =
                                        props.row.original.isPublic;
                                    HandleException(error);
                                }
                            }}
                        />
                    ),
                },
                {
                    id: "action",
                    header: "",
                    Cell: ({ row }) => (
                        <div>
                            <Button
                                title="Edit"
                                className="btn btn-flat"
                                variant="success"
                                size="sm"
                                onClick={() => {
                                    navigate("../update-product", {
                                        state: {
                                            product: row.original,
                                        },
                                    });
                                }}
                            >
                                <i className="fa fa-edit"></i>
                            </Button>

                            <Button
                                title="Media"
                                className="btn btn-flat m-1"
                                variant="warning"
                                size="sm"
                                onClick={() => {
                                    navigate("../product-media/" + row.original.id);
                                }}
                            >
                                <i className="fa fa-images"></i>
                            </Button>

                            <Button
                                title="Delete"
                                className="btn btn-flat m-1"
                                variant="danger"
                                size="sm"
                                onClick={() => {
                                    deleteProduct(row.original.id);
                                }}
                            >
                                <i className="fa fa-trash"></i>
                            </Button>
                        </div>
                    ),
                },
            ] as MRT_ColumnDef<IProduct>[],
        []
    );

    return (
        <>
            <section className="content-header"></section>
            <section className="content">
                <div className="card card-outline">
                    <div className="card-header with-border">
                        <h4 className="card-title">Products</h4>
                        <div className="card-tools">
                            <NavLink
                                to="../add-product"
                                className="btn btn-default btn-flat btn-sm"
                            >
                                <i className="fa fa-plus"></i>
                                &nbsp; Add New
                            </NavLink>
                        </div>
                    </div>
                    <div className="card-body">
                     <MRT
                            columns={columns}
                            data={products}
                            initialState={{
                                columnVisibility: {
                                    code: false,
                                    hsnCode: false,
                                    cbm: false
                                },
                            }}
                        />
                    </div>
                    {loading ? (
                        <div className="overlay">
                            <i className="fa fa-spinner fa-spin"></i>
                        </div>
                    ) : undefined}
                </div>
            </section>
        </>
    );
}
