import { useEffect, useMemo, useState } from "react";
import { Form, FormGroup } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import { mySwal } from "../../config/sw-config";
import { HandleException } from "../../helpers/exception-handler";
import { useAppDispatch, useAppSelector } from "../../hooks/store-hooks";
import { fetch, remove, update } from "../../slices/collection-slice";
import { ICollection } from "../../types/collection-type";
import MRT, { MRT_ColumnDef } from "material-react-table";

export default function Collection() {
    const dispatch = useAppDispatch();
    const collections = useAppSelector((s) => s.collection.collections);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        async function loadCollections() {
            try {
                setLoading(true);
                await dispatch(fetch()).unwrap();
            } catch (error) {
                HandleException(error);
            } finally {
                setLoading(false);
            }
        }
        if (!collections?.length) {
            loadCollections();
        }
    }, [dispatch]);

    //update
    const updateCollection = async (coll: ICollection) => {
        try {
            await dispatch(
                update({
                    name: coll.name,
                    description: coll.description,
                    isPublished: coll.isPublished,
                    id: coll.id,
                })
            ).unwrap();
        } catch (error) {
            HandleException(error);
        }
    };

    //delete collection
    const deleteCollection = (id: number) => {
        mySwal
            .fire({
                titleText: "Confirm",
                text: "Are you sure?",
                icon: "error",
                showCancelButton: true,
                confirmButtonText: "Yes, Delete!",
            })
            .then(async (res) => {
                if (res.isConfirmed) {
                    try {
                        setLoading(true);
                        await dispatch(remove(id)).unwrap();
                    } catch (error) {
                        HandleException(error);
                    } finally {
                        setLoading(false);
                    }
                }
            });
    };

    //table columns
    const columns = useMemo(
        () =>
            [
                {
                    accessorKey: "name",
                    header: "Name",
                },
                {
                    accessorKey: "description",
                    header: "Description",
                },
                {
                    accessorKey: "isPublished",
                    header: "Published",
                    Cell: ({ row }) => (
                        <Form.Check
                            type="switch"
                            defaultChecked={row.original.isPublished}
                            onChange={async (e) => {
                                updateCollection({
                                    ...row.original,
                                    isPublished: e.target.checked,
                                });
                            }}
                        />
                    ),
                },
                {
                    id: "actions",
                    Cell: ({ row }) => (
                        <div>
                            <Link
                                title="Edit"
                                to={"../update-collection/" + row.original.id}
                                className="btn btn-flat btn-info btn-sm"
                            >
                                <i className="fa fa-edit"></i>
                            </Link>

                            <Link
                                title="Manage Collection"
                                to={"../manage-collection/" + row.original.id}
                                className="btn btn-flat btn-success btn-sm ml-2"
                            >
                                <i className="fa fa-images"></i>
                            </Link>

                            <button
                                onClick={() => {
                                    deleteCollection(row.original.id);
                                }}
                                title="Delete"
                                type="button"
                                className="ml-2 btn btn-danger btn-sm btn-flat"
                            >
                                <i className="fa fa-trash"></i>
                            </button>
                        </div>
                    ),
                },
            ] as MRT_ColumnDef<ICollection>[],
        []
    );

    return (
        <>
            <section className="content-header"></section>
            <section className="content">
                <div className="card card-outline card-danger">
                    <div className="card-header with-border">
                        <h4 className="card-title">Collections</h4>
                        <div className="card-tools">
                            <NavLink
                                to="../add-collection"
                                className="btn btn-default btn-flat btn-sm"
                            >
                                <i className="fa fa-plus"></i> Add New
                            </NavLink>
                        </div>
                    </div>
                    <div className="card-body">
                        <MRT data={collections} columns={columns}/>
                    </div>
                    {loading ? (
                        <div className="overlay">
                            <i className="fa fa-spinner fa-spin"></i>
                        </div>
                    ) : undefined}
                </div>
            </section>
        </>
    );
}
