import { useEffect, useState } from "react";
import { FormGroup } from "react-bootstrap";
import { NavLink, useParams } from "react-router-dom";
import { HandleException } from "../../helpers/exception-handler";
import { useAppDispatch, useAppSelector } from "../../hooks/store-hooks";
import {
  fetch,
  add,
  addProduct,
  removeProduct,
} from "../../slices/collection-slice";
import { fetch as fetchProducts } from "../../slices/product-slice";

export default function ManageCollection() {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const collection = useAppSelector((s) =>
    s.collection.collections.find((x) => x.id === +id!)
  );
  const products = useAppSelector((s) => s.product.products);
  const [loading, setLoading] = useState(false);
  const [searchProduct, setSearchProduct] = useState("");
  const [searchColProduct, setSearchColProduct] = useState("");

  useEffect(() => {
    try {
      if (!collection) {
        dispatch(fetch()).unwrap();
      }

      if (!products?.length) {
        dispatch(fetchProducts()).unwrap();
      }
    } catch (error) {
      HandleException(error);
    }
  }, []);

  //Add to collection
  const addToCollection = async (id: number) => {
    try {
      setLoading(true);
      await dispatch(
        addProduct({
          collectionId: collection!.id,
          productId: id,
        })
      ).unwrap();
    } catch (error) {
      HandleException(error);
    } finally {
      setLoading(false);
    }
  };

  //remove from collection
  const removeFromCollection = async (id: number) => {
    try {
      setLoading(true);
      await dispatch(
        removeProduct({
          collectionId: collection!.id,
          productId: id,
        })
      ).unwrap();
    } catch (error) {
      HandleException(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <section className="content-header">
        <NavLink
          to="../collections"
          className="btn btn-flat btn-sm btn-warning"
        >
          <i className="fa fa-arrow-left"></i> Back To List
        </NavLink>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-md-6">
            <div className="card card-outline card-danger">
              <div className="card-header with-border">
                <h4 className="card-title">Products</h4>
              </div>
              <div className="card-body">
                <FormGroup>
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Search"
                    value={searchProduct}
                    onChange={(e) => {
                      setSearchProduct(e.target.value);
                    }}
                  />
                </FormGroup>
                <div
                  style={{
                    maxHeight: "400px",
                    overflowY: "scroll",
                  }}
                >
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Category</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {products
                        .filter(
                          (p) =>
                            p.name
                              .toLowerCase()
                              .includes(searchProduct.toLowerCase()) &&
                            !collection?.products?.find((y) => y.id === p.id)
                        )
                        .map((p, i) => (
                          <tr key={i}>
                            <td>{p.name}</td>
                            <td>{p.category.name}</td>
                            <td>
                              <button
                                type="button"
                                className="btn btn-flat btn-sm btn-info"
                                onClick={() => {
                                  addToCollection(p.id);
                                }}
                              >
                                <i className="fas fa-angle-double-right"></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
              {loading ? (
                <div className="overlay">
                  <i className="fa fa-spinner fa-spin"></i>
                </div>
              ) : undefined}
            </div>
          </div>
          <div className="col-md-6">
            <div className="card card-outline card-danger">
              <div className="card-header with-border">
                <h4 className="card-title">Collection Items</h4>
              </div>
              <div className="card-body">
                <FormGroup>
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Search"
                    value={searchColProduct}
                    onChange={(e) => {
                      setSearchColProduct(e.target.value);
                    }}
                  />
                </FormGroup>
                <div
                  style={{
                    maxHeight: "400px",
                    overflowY: "scroll",
                  }}
                >
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Category</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {collection?.products
                        ?.filter((p) =>
                          p.name
                            .toLowerCase()
                            .includes(searchColProduct.toLowerCase())
                        )
                        .map((p, i) => (
                          <tr key={i}>
                            <td>{p.name}</td>
                            <td>{p.category.name}</td>
                            <td>
                              <button
                                onClick={() => {
                                  removeFromCollection(p.id);
                                }}
                                type="button"
                                className="btn btn-flat btn-sm btn-danger"
                              >
                                <i className="fas fa-trash"></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
              {loading ? (
                <div className="overlay">
                  <i className="fa fa-spinner fa-spin"></i>
                </div>
              ) : undefined}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
