import { FormEvent, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { createQuotation } from "../../AAL/quotation-api-service";
import { mySwal } from "../../config/sw-config";
import { HandleException } from "../../helpers/exception-handler";

export function CreateQuotation() {
  const [to, setTo] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const nameInputRef = useRef<HTMLInputElement>(null);

  const submit = async (e: FormEvent) => {
    e.preventDefault();
    if (!to) {
      mySwal.fire({
        titleText: "Error",
        text: "Please enter party name.",
        icon: "error",
      });
      return;
    }
    try {
      setLoading(true);
      await createQuotation({
        to,
        email,
        phoneNumber,
      });

      mySwal.fire({
        titleText: "Created",
        text: "Quotation created.",
        icon: "success",
        toast: true,
        timer: 2000,
      });

      setTo("");
      setEmail("");
      setPhoneNumber("");
      nameInputRef.current?.focus();
    } catch (error) {
      HandleException(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <section className="content-header">
        <Link to="../quotations" className="btn btn-flat btn-warning">
          <i className="fa fa-arrow-left"></i> Back To List
        </Link>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-md-4">
            <form onSubmit={submit}>
              <div className="card card-outline card-danger">
                <div className="card-header with-border">
                  <h4 className="card-title">Create Quotation</h4>
                </div>
                <div className="card-body">
                  <div className="form-group">
                    <label>Party Name</label>
                    <input
                      autoFocus
                      ref={nameInputRef}
                      value={to}
                      onChange={(e) => {
                        setTo(e.target.value);
                      }}
                      type="text"
                      className="form-control"
                    />
                  </div>
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      type="email"
                      className="form-control"
                    />
                  </div>

                  <div className="form-group">
                    <label>Phone Number</label>
                    <input
                      value={phoneNumber}
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (e.target.value === "" || re.test(e.target.value)) {
                          setPhoneNumber(e.target.value);
                        }
                      }}
                      type="text"
                      maxLength={10}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="card-footer text-right">
                  <input
                    type="submit"
                    className="btn btn-danger btn-flat"
                    value="Create"
                  />
                </div>
                {loading ? (
                  <div className="overlay">
                    <i className="fa fa-spin fa-spinner"></i>
                  </div>
                ) : undefined}
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}
