import { ICategory } from "./category-type";
import { IColor } from "./color-type";
import { IFinishType } from "./finish-type-type";
import { IMaterialType } from "./material-type-type";

export interface IProduct {
    id: number;
    name: string;
    code: string;
    description: string;
    size: string;
    hsnCode: string;
    cbm: number;
    price: number;
    grossWeight: number;
    netWeight: number;
    remark: string;
    isPublic: boolean;
    newBadge: boolean;    
    finish: IFinishType;
    material: IMaterialType;
    category: ICategory;
    color: IColor;
    medias: IProductMedia[];
    dateCreated: Date;
}

export interface IProductCreatePayload {
    code: string;
    name:string;
    description: string;
    finishId: number;
    size: string;
    materialId: number;
    hsnCode?: string;
    cbm: number;
    price?: number;
    grossWeight?: number;
    netWeight?: number;
    remark?: string;
    isPublic: boolean;
    categoryId: number;
    newBadge: boolean;
    colorId?: number;
}

export interface IProductUpdatePayload extends IProductCreatePayload {
    id:number;
}

export interface IProductUpdateFlagPayload {
    id: number;
    isPublic?: boolean;
    newBadge?: boolean;
}

//#region Product Media
export enum EProductMediaType{
    Photo=0,
    Video=1
}

export interface IProductMedia {
    id: number;
    file: string;
    thumbFile: string;
    type: EProductMediaType
    isPublic: boolean;
    isMain: boolean;
    productId: number;
    dateCreated: Date;
}

export interface IProductMediaUpdatePayload {
    id: number;
    isPublic: boolean;
    isMain: boolean;
    productId: number;
}

export interface IProductMediaAddPayload{
    productId: number;
    files: FileList;
    type: EProductMediaType;
    isPublic: boolean;
    isMain: boolean;    
}
//#endregion