import { useState } from "react";
import { Button, Form, FormGroup, FormLabel, Modal } from "react-bootstrap";
import { mySwal } from "../../config/sw-config";
import { HandleException } from "../../helpers/exception-handler";
import { useAppDispatch } from "../../hooks/store-hooks";
import { add } from "../../slices/material-type-slice";

type TProps = {
  visible: boolean;
  onClose: () => void;
  onAdded?: () => void;
};

export default function AddMaterialType(props: TProps) {
  //form values
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();

  const addMaterial = async () => {
    if (!name) {
      mySwal.fire({
        titleText: "",
        text: "Please enter material type.",
        timer: 2000,
        icon: "error",
      });
      return;
    }

    try {
      setLoading(true);
      await dispatch(
        add({
          name,
        })
      ).unwrap();
      mySwal.fire({
        titleText: "Added",
        toast: true,
        text: "Material Type Added Successfully.",
        timer: 2000,
        position: "top-right",
      });
      setName("");
      if (props.onAdded) {
        props.onAdded();
      }
    } catch (error) {
      HandleException(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      show={props.visible}
      onHide={() => {
        if (props.onClose) {
          props.onClose();
        }
      }}
    >
      <Form
        onSubmit={(e) => {
          addMaterial();
          e.preventDefault();
        }}
      >
        <Modal.Header closeButton closeLabel="hello">
          <Modal.Title>Add New Material Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            <FormLabel>Material Type</FormLabel>
            <input
              value={name}
              onChange={(e) => setName(e.target.value)}
              autoFocus
              type="text"
              className="form-control"
            />
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={loading}
            type="submit"
            variant="primary"
            className="btn-flat"
            onClick={() => {}}
          >
            {loading ? "Please wait..." : "Save"}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
