import { ILoginRequest, ILoginResponse, ILogoutRequest } from "../types/auth-type";
import * as base from "./base-api-service";

/**
 * Login
 * @param data Login Request
 * @returns Login Response
 */
export  const login: (data: ILoginRequest)=> Promise<ILoginResponse> = (data: ILoginRequest)=> {
    return base.post("accountapi/login",data);
}

/**
 * Logout
 * @param data Logout Request Data
 */
export const logout: (data: ILogoutRequest) => Promise<void> = (data: ILogoutRequest)=> {
    return base.post("accountapi/logout",data)
}