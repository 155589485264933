import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as API from "../AAL/product-api-service";
import { IProduct, IProductCreatePayload, IProductMedia, IProductMediaAddPayload, IProductMediaUpdatePayload, IProductUpdateFlagPayload, IProductUpdatePayload } from "../types/product-type";

// Define a type for the slice state
interface DefaultState {
  products: IProduct[];
}

// Define the initial state using that type
const initialState: DefaultState = {
  products: [],
};

//#region
//Get All Products
const fetch = createAsyncThunk("product/fetch", async () => {
  let data = await API.getAll();
  return data;
});

//Add Product
const add = createAsyncThunk(
  "product/add",
  async (payload: IProductCreatePayload, thunkAPI) => {
    let data = await API.add(payload);
    return data;
  }
);


//Add Product Media
const addMedia = createAsyncThunk(
  "product/addMedia",
  async (payload: IProductMediaAddPayload, thunkAPI) => {
    let data = await API.addMedia(payload);
    return data;
  }
);

//Update Product
const update = createAsyncThunk(
  "product/update",
  async (payload: IProductUpdatePayload, thunkAPI) => {
    let data = await API.update(payload);
    return data;
  }
);

//Update ProductFlags
const updateFlags = createAsyncThunk(
  "product/updateFlags",
  async (payload: IProductUpdateFlagPayload, thunkAPI) => {
    await API.updateFlag(payload);
    return payload;
  }
);

//Update Product Media Flags
const updateMedia = createAsyncThunk(
  "product/updateMedia",
  async (payload: IProductMediaUpdatePayload, thunkAPI) => {
    await API.updateMedia(payload);
    return payload;
  }
);

//remove Product
const remove = createAsyncThunk(
  "product/remove",
  async (payload: number, thunkAPI) => {
    await API.del(payload);
    return payload;
  }
);

//remove Product Media
const removeMedia = createAsyncThunk(
  "product/removeMedia",
  async (payload: IProductMedia, thunkAPI) => {
    await API.deleteMedia(payload.id);
    return payload;
  }
);
//#endregion

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetch.fulfilled, (s, a) => {
        s.products = a.payload;
      })
      .addCase(add.fulfilled, (s, a) => {
        s.products.push(a.payload);
      })
      .addCase(addMedia.fulfilled, (s, a) => {
        let prod = s.products.find(x=> x.id === a.payload[0].productId);
        if(prod){
          a.payload.forEach(item=> {
            prod?.medias.push(item);
          })
        }
      })
      .addCase(update.fulfilled, (s, a) => {
        let c = s.products.find((x) => x.id === a.payload.id);
        if (c) {
          Object.assign(c, a.payload);
        }
      })
      .addCase(updateFlags.fulfilled, (s, a) => {
        let c = s.products.find((x) => x.id === a.payload.id);
        if (c) {
          if(a.payload.isPublic !== null && a.payload.isPublic !== undefined){
            c.isPublic = a.payload.isPublic;
          }
          
          if(a.payload.newBadge !== null && a.payload.newBadge !== undefined){
            c.newBadge = a.payload.newBadge;
          }
        }
      })
      .addCase(remove.fulfilled, (s,a)=> {
        let cat = s.products.find(x=> x.id === a.payload);
        if(cat){
          let index = s.products.indexOf(cat);
          if(index>-1){
            s.products.splice(index,1);
          }
        }
      })
      .addCase(removeMedia.fulfilled, (s,a)=> {
        let cat = s.products.find(x=> x.id === a.payload.productId);
        if(cat){
          let m = cat.medias.find(x=> x.id  === a.payload.id);
          if(m){
            let index = cat.medias.indexOf(m);
            if(index>-1){
              cat.medias.splice(index,1);
            }
          }          
        }
      })
      .addCase(updateMedia.fulfilled, (s,a)=> {
        let cat = s.products.find(x=> x.id === a.payload.productId);
        if(cat){
          let m = cat.medias.find(x=> x.id  === a.payload.id);
          if(m){
            m.isMain = a.payload.isMain;
            m.isPublic = a.payload.isPublic;
          }          
        } 
      })
  },
});

export { update, add, fetch, remove, updateFlags, addMedia, removeMedia, updateMedia };
export default productSlice.reducer;
