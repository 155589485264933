import { VERSIONCODE } from "../../config/config";

export function Footer() {
  return (
    <footer className="main-footer">
      <strong>
        &copy; {new Date().getFullYear()} <a href="">Angira Art</a>.
      </strong>
      All rights reserved.
      <div className="float-right d-none d-sm-inline-block">
        <b>Version</b> {VERSIONCODE}
      </div>
    </footer>
  );
}
