import React, { useEffect, useMemo } from "react";
import "./App.css";
import { Login } from "./pages/login/login";
import { Route, Routes } from "react-router-dom";
import { Admin } from "./pages/admin/admin";
import { Dashboard } from "./pages/dashboard/dashboard";
import { Categories } from "./pages/categories/categories";
import store from "./store";
import { Provider } from "react-redux";
import { Colors } from "./pages/color/colors";
import { FinishTypes } from "./pages/finish-type/finish-type";
import { MaterialTypes } from "./pages/material-type/material-type";
import AddProduct from "./pages/product/add-product";
import Products from "./pages/product/products";
import UpdateProduct from "./pages/product/update-product";
import ProductMedia from "./pages/product/manage-product-media";
import Collection from "./pages/collection/collection";
import AddCollection from "./pages/collection/add-collection";
import UpdateCollection from "./pages/collection/update-collection";
import ManageCollection from "./pages/collection/manage-collection";
import EmailSubscriptions from "./pages/email-subscription/email-subscriptions";
import Enquiries from "./pages/enquiry/enquiries";
import ViewEnquiry from "./pages/enquiry/view-enquiry";
import SliderBanners from "./pages/site-management/slide-banners";
import FeaturedProducts from "./pages/site-management/featured-products";
import AboutUsText from "./pages/site-management/about-us-text";
import TeamMembers from "./pages/team-member/team-members";
import AddMember from "./pages/team-member/add-member";
import EditMember from "./pages/team-member/edit-member";
import Testimonials from "./pages/site-management/testimonial";
import ManageAddress from "./pages/site-management/address";
import ManageTiming from "./pages/site-management/office-timing";
import { RequireAuth } from "./components/auth/require-auth";
import { Quotations } from "./pages/quotation/quotations";
import { CreateQuotation } from "./pages/quotation/create-quotation";
import NotFound from "./pages/error-pages/not-found";
import { UpdateQuotation } from "./pages/quotation/update-quotation";
import { ManageQuotationItems } from "./pages/quotation/manage-items";
import { setupInterceptors } from "./config/api-config";
import { useAppDispatch, useAppSelector } from "./hooks/store-hooks";
import { logout, userInfo } from "./slices/auth-slice";
import { HandleException } from "./helpers/exception-handler";
import { AppContextProvider } from "./context/app-context";

function App() {
    const dispatch = useAppDispatch();
    const user = useAppSelector(userInfo);
    useMemo(() => {
        setupInterceptors(user?.token ?? "", async () => {
            try {
                await dispatch(logout());
            } catch (error) {
                HandleException(error);
            }
        });
    }, [dispatch, user]);
    return (
        <AppContextProvider>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route
                    path="/"
                    element={
                        <RequireAuth>
                            <Admin />
                        </RequireAuth>
                    }
                >
                    <Route path="" element={<Dashboard />} />
                    <Route path="categories" element={<Categories />} />
                    <Route path="colors" element={<Colors />} />
                    <Route path="finish-type" element={<FinishTypes />} />
                    <Route path="material-type" element={<MaterialTypes />} />
                    <Route path="add-product" element={<AddProduct />} />
                    <Route path="update-product" element={<UpdateProduct />} />
                    <Route
                        path="product-media/:id"
                        element={<ProductMedia />}
                    />
                    <Route path="products" element={<Products />} />
                    <Route path="add-collection" element={<AddCollection />} />
                    <Route
                        path="update-collection/:id"
                        element={<UpdateCollection />}
                    />
                    <Route
                        path="manage-collection/:id"
                        element={<ManageCollection />}
                    />
                    <Route path="collections" element={<Collection />} />
                    <Route
                        path="email-subscriptions"
                        element={<EmailSubscriptions />}
                    />
                    <Route path="enquiries" element={<Enquiries />} />
                    <Route path="enquiries/:id" element={<ViewEnquiry />} />
                    <Route path="slider-banners" element={<SliderBanners />} />
                    <Route
                        path="featured-products"
                        element={<FeaturedProducts />}
                    />
                    <Route path="aboutus" element={<AboutUsText />} />
                    <Route path="team-members" element={<TeamMembers />} />
                    <Route path="add-member" element={<AddMember />} />
                    <Route path="edit-member/:id" element={<EditMember />} />
                    <Route path="testimonials" element={<Testimonials />} />
                    <Route path="manage-address" element={<ManageAddress />} />
                    <Route path="manage-timing" element={<ManageTiming />} />
                    <Route path="quotations" element={<Quotations />} />
                    <Route
                        path="create-quotation"
                        element={<CreateQuotation />}
                    />
                    <Route
                        path="update-quotation/:id"
                        element={<UpdateQuotation />}
                    />
                    <Route
                        path="quotation-items/:id"
                        element={<ManageQuotationItems />}
                    />
                </Route>
                <Route path="*" element={<NotFound />} />
            </Routes>
        </AppContextProvider>
    );
}

export default App;
