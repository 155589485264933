import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from "draft-js";
import { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { getAddress, updateAddress } from "../../AAL/address-api-service";
import { HandleException } from "../../helpers/exception-handler";
import convertToHtml from "draftjs-to-html";
import { mySwal } from "../../config/sw-config";

export default function ManageAddress() {
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState<EditorState>(
    EditorState.createEmpty()
  );

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);
        let data = await getAddress();
        if (data) {
          let contentArray = convertFromHTML(data);
          let cs = ContentState.createFromBlockArray(
            contentArray.contentBlocks
          );
          setAddress(EditorState.createWithContent(cs));
        }
      } catch (error) {
        HandleException(error);
      } finally {
        setLoading(false);
      }
    }
    loadData();
  }, []);

  const update = async () => {
    try {
      setLoading(true);
      await updateAddress({
        address: convertToHtml(convertToRaw(address.getCurrentContent())),
      });
      mySwal.fire({
        titleText: "Updated",
        text: "Address updated successfully.",
        icon: "success",
        toast: true,
        timer: 2000,
      });
    } catch (error) {
      HandleException(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <section className="content-header"></section>
      <section className="content">
        <div className="row">
          <div className="col-md-6">
            <div className="card card-outline card-danger">
              <div className="card-header with-border">
                <h4 className="card-title">Address</h4>
              </div>
              <div className="card-body">
                <Editor
                  editorStyle={{ minHeight: "250px" }}
                  editorState={address}
                  onEditorStateChange={(s) => {
                    setAddress(s);
                  }}
                  placeholder="Write something!"
                />
              </div>
              <div className="card-footer text-right">
                <button
                  onClick={() => update()}
                  type="button"
                  className="btn btn-danger btn-flat"
                >
                  Save
                </button>
              </div>
              {loading ? (
                <div className="overlay">
                  <i className="fa fa-spinner fa-spin"></i>
                </div>
              ) : undefined}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
