import { ICategoryCreatePayload, ICategory, ICategoryUpdatePayload } from "../types/category-type";
import * as base from "./base-api-service";

/**
 * Get list of categories
 */
export const getAll: () => Promise<ICategory[]> = () => {
  console.log("called");
  return base.get("category/getall");
};

/**
 * Get category by id
 * @param id category id
 * @returns Category
 */
export const get: (id: number) => Promise<ICategory> = (id: number) => {
  return base.get("category/get", {
    id,
  });
};

/**
 * Add new category
 * @param data Payload
 * @returns Category
 */
export const add: (data: ICategoryCreatePayload) => Promise<ICategory> = (
  data: ICategoryCreatePayload
) => {
  let fd = new FormData();
  for (let key in data as any) {
    fd.append(key, (data as any)[key]);
  }
  return base.post<ICategory>("category/add", fd);
};

/**
 * Update category
 * @param data Payload
 * @returns Category
 */
export const update: (data: ICategoryUpdatePayload) => Promise<ICategory> = (
  data: ICategoryUpdatePayload
) => {
  let fd = new FormData();
  for (let key in data as any) {
    fd.append(key, (data as any)[key]);
  }
  return base.patch<ICategory>("category/update", fd);
};

/**
 * Delete category
 * @param id Category Id
 * @returns void
 */
export const del: (id: number) => Promise<void> = (id: number) => {
  return base.del("category/delete", {
    id,
  });
};
