import { ITestimonial, ITestimonialAddPayload, ITestimonialUpdatePayload } from "../types/testimonial-type";
import * as base from "./base-api-service";

/**
 * Get list of Testimonials
 */
export const getAll: () => Promise<ITestimonial[]> = () => {  
  return base.get("site/gettestimonials");
};

/**
 * Get testimonial
 * @param id Testimonial id
 * @returns Testimonial
 */
export const get: (id: number) => Promise<ITestimonial> = (id: number) => {
  return base.get("site/gettestimonial", {
    id,
  });
};

/**
 * Add new 
 * @param data Payload
 * @returns Testimonial
 */
export const add: (data: ITestimonialAddPayload) => Promise<ITestimonial> = (
  data: ITestimonialAddPayload
) => {
  return base.post("site/addtestimonial", data);
};

/**
 * Update testimonial
 * @param data Payload
 * @returns Testimonial
 */
export const update: (data: ITestimonialUpdatePayload) => Promise<ITestimonial> = (
  data: ITestimonialUpdatePayload
) => {
  return base.patch("site/updatetestimonial", data);
};

/**
 * Delete testimonial
 * @param id Testimonial Id
 */
export const del: (id: number) => Promise<void> = (id: number) => {
  return base.del("site/deletetestimonial", {
    id,
  });
};
