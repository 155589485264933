import { useContext, useEffect, useState } from "react";
import { Button, Form, FormGroup, FormLabel, Modal } from "react-bootstrap";
import { mySwal } from "../../config/sw-config";
import { appContext } from "../../context/app-context";
import { HandleException } from "../../helpers/exception-handler";
import { useAppDispatch } from "../../hooks/store-hooks";
import { update } from "../../slices/category-slice";
import { ICategory } from "../../types/category-type";

type TProps = {
  visible: boolean;
  onClose: () => void;
  onUpdated?: () => void;
  category: ICategory;
};

export default function UpdateCategory(props: TProps) {
  //form values
  const { getImage, getVideo } = useContext(appContext);
  const [name, setName] = useState("");
  const [thumbnail, setThumbnail] = useState<File | null>(null);
  const [currentThumb, setCurrentThumb] = useState("");
  const [id, setId] = useState<number>();
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setName(props.category.name);
    setCurrentThumb(getImage!(props.category.thumbnail));
    setId(props.category.id);
  }, [props.category, getImage]);

  const updateCategory = async () => {
    if (!name) {
      mySwal.fire({
        titleText: "",
        text: "Please enter category name.",
        timer: 2000,
        icon: "error",
      });
      return;
    }

    try {
      setLoading(true);
      let updated = await dispatch(
        update({
          name,
          thumbnail,
          id: props.category.id,
        })
      ).unwrap();
      setCurrentThumb(getImage!(updated.thumbnail));
      mySwal.fire("Updated", "Category updated successfully.", "success");
      if (props.onUpdated) {
        props.onUpdated();
      }
    } catch (error) {
      HandleException(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      show={props.visible}
      onHide={() => {
        if (props.onClose) {
          props.onClose();
        }
      }}
    >
      <Form
        onSubmit={(e) => {
          updateCategory();
          e.preventDefault();
        }}
      >
        <Modal.Header closeButton closeLabel="hello">
          <Modal.Title>Update Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            <FormLabel>Category Name</FormLabel>
            <input
              onChange={(e) => setName(e.target.value)}
              autoFocus
              value={name}
              type="text"
              className="form-control"
            />
          </FormGroup>
          <FormGroup className="mt-2">
            <img
              src={currentThumb}
              style={{ height: "120px" }}
              alt=""
              className="img-thumbnail"
            />
          </FormGroup>
          <FormGroup className="mt-2">
            <FormLabel>Select Thumbnail</FormLabel>
            <input
              type="file"
              onChange={(e) => {
                if (e.target?.files?.length && e.target.files[0]) {
                  setCurrentThumb(URL.createObjectURL(e?.target.files[0]));
                  setThumbnail(e.target?.files[0]);
                }
              }}
              className="form-control"
              accept="image/*"
            />
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={loading}
            type="submit"
            variant="primary"
            className="btn-flat"
            onClick={() => {}}
          >
            {loading ? "Please wait..." : "Update"}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
