import axios from "axios";
import { handleDates } from "../helpers/date-parser";
//const BaseUrl = "https://localhost:7107/api"
const BaseUrl = "https://angiraartexports.com/api";

const client = axios.create({
	baseURL: BaseUrl,
});
export { client };
export const ImageBaseUrl = `${BaseUrl}/AdminPhoto/get?photo=`;
export const VideoBaseUrl = `${BaseUrl}/AdminPhoto/video?video=`;

export const setupInterceptors = (
	token: string,
	logout: () => Promise<void>
) => {
	client.interceptors.request.use((req) => {
		if (token) {
			req.headers = {
				...req.headers,
				Authorization: "bearer " + token,
			};
		}
		return req;
	});
	client.interceptors.response.use(
		(originalResponse) => {
			handleDates(originalResponse.data);
			return originalResponse;
		},
		async (error) => {
			if (error?.response?.status === 401) {
				await logout();
				window.location.href = window.location.origin + "/login";
			}
			throw error;
		}
	);
};
