import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from "draft-js";
import { useContext, useEffect, useRef, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import {
  deleteAboutUsBanner,
  getBanner,
  getLongText,
  getShortText,
  updateBanner,
  updateLongText,
  updateShortText,
} from "../../AAL/about-us-api-service";
import { mySwal } from "../../config/sw-config";
import { HandleException } from "../../helpers/exception-handler";
import convertToHtml from "draftjs-to-html";
import { FormGroup } from "react-bootstrap";
import { appContext } from "../../context/app-context";

export default function AboutUsText() {
  const { getImage, getVideo } = useContext(appContext);

  const [longText, setLongText] = useState<EditorState>(
    EditorState.createEmpty()
  );
  const [shortText, setShortText] = useState<EditorState>(
    EditorState.createEmpty()
  );
  const [bannerUrl, setBannerUrl] = useState("");
  const [banner, setBanner] = useState<File>();
  const [loadingShortText, setLoadingShortText] = useState(false);
  const [loadingLongText, setLoadingLongText] = useState(false);
  const [loadingBanner, setLoadingBanner] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    async function loadData() {
      try {
        setLoadingShortText(true);
        setLoadingLongText(true);
        setLoadingBanner(true);
        let result = await Promise.all([
          getLongText(),
          getShortText(),
          getBanner(),
        ]);
        if (result[0]) {
          let contentArray = convertFromHTML(result[0]);
          let cs = ContentState.createFromBlockArray(
            contentArray.contentBlocks
          );
          setLongText(EditorState.createWithContent(cs));
        }
        if (result[1]) {
          let contentArray = convertFromHTML(result[1]);
          let cs = ContentState.createFromBlockArray(
            contentArray.contentBlocks
          );
          setShortText(EditorState.createWithContent(cs));
        }
        if (result[2]) {
          setBannerUrl(getImage!(result[2]));
        }
      } catch (error) {
        HandleException(error);
      } finally {
        setLoadingShortText(false);
        setLoadingLongText(false);
        setLoadingBanner(false);
      }
    }
    loadData();
  }, []);

  const updateShort = async () => {
    try {
      setLoadingShortText(true);
      await updateShortText({
        text: convertToHtml(convertToRaw(shortText.getCurrentContent())),
      });
      mySwal.fire({
        titleText: "Updated",
        text: "About us text updated successfully.",
        icon: "success",
        toast: true,
        timer: 2000,
      });
    } catch (error) {
      HandleException(error);
    } finally {
      setLoadingShortText(false);
    }
  };

  const updateLong = async () => {
    try {
      setLoadingLongText(true);
      await updateLongText({
        text: convertToHtml(convertToRaw(longText.getCurrentContent())),
      });
      mySwal.fire({
        titleText: "Updated",
        text: "About us text updated successfully.",
        icon: "success",
        toast: true,
        timer: 2000,
      });
    } catch (error) {
      HandleException(error);
    } finally {
      setLoadingLongText(false);
    }
  };

  const updateAboutBanner = async () => {
    if (!banner) {
      mySwal.fire({
        text: "Please select image file.",
        toast: true,
        icon: "error",
        timer: 2000,
      });
      return;
    }
    try {
      setLoadingLongText(true);
      await updateBanner({
        photo: banner,
      });
      await mySwal.fire({
        titleText: "Updated",
        text: "About us banner updated successfully.",
        icon: "success",
        toast: true,
        timer: 2000,
      });
      setBanner(undefined);
      fileInputRef!.current!.value = "";
    } catch (error) {
      HandleException(error);
    } finally {
      setLoadingLongText(false);
    }
  };

  const removeBanner = async () => {
    mySwal
      .fire({
        titleText: "Confirm",
        icon: "warning",
        text: "Are you sure?",
        showCancelButton: true,
        confirmButtonText: "Delete",
      })
      .then(async (res) => {
        if (res.isConfirmed) {
          try {
            setLoadingBanner(true);
            await deleteAboutUsBanner();
            mySwal.fire({
              titleText: "Removed",
              text: "About us page banner removed.",
              timer: 2000,
              icon: "success",
            });
            setBanner(undefined);
            fileInputRef!.current!.value = "";
            setBannerUrl("");
          } catch (error) {
            HandleException(error);
          } finally {
            setLoadingBanner(false);
          }
        }
      });
  };

  return (
    <>
      <section className="content-header"></section>
      <section className="content">
        <div className="card card-outline card-danger">
          <div className="card-header with-border">
            <h4 className="card-title">About Us Banner</h4>
          </div>
          <div className="card-body">
            <img className="img-fluid" src={bannerUrl} alt="" />
            <FormGroup>
              <input
                ref={fileInputRef}
                type="file"
                onChange={(e) => {
                  if (e.target.files?.length) {
                    setBanner(e.target.files[0]);
                    setBannerUrl(URL.createObjectURL(e.target.files[0]));
                  }
                }}
                className="form-control"
              />
            </FormGroup>
          </div>
          <div className="card-footer text-right">
            <button
              disabled={!bannerUrl}
              onClick={() => removeBanner()}
              className="btn btn-danger btn-flat mr-3"
            >
              Remove
            </button>

            <button
              onClick={() => updateAboutBanner()}
              className="btn btn-success btn-flat"
            >
              Update Banner
            </button>
          </div>
          {loadingBanner ? (
            <div className="overlay">
              {" "}
              <i className="fa fa-spinner fa-spin"></i>
            </div>
          ) : undefined}
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="card card-outline card-danger">
              <div className="card-header with-border">
                <h4 className="card-title">About Us Long Text</h4>
              </div>
              <div className="card-body">
                <Editor
                  editorStyle={{ minHeight: "300px" }}
                  editorState={longText}
                  onEditorStateChange={(s) => {
                    setLongText(s);
                  }}
                  placeholder="Write something!"
                />
              </div>
              <div className="card-footer text-right">
                <button
                  onClick={() => updateLong()}
                  type="button"
                  className="btn btn-danger btn-flat "
                >
                  Save
                </button>
              </div>
              {loadingLongText ? (
                <div className="overlay">
                  <i className="fa fa-spinner fa-spin"></i>
                </div>
              ) : undefined}
            </div>
          </div>

          <div className="col-md-6">
            <div className="card card-outline card-danger">
              <div className="card-header with-border">
                <h4 className="card-title">About Us Short Text</h4>
              </div>
              <div className="card-body">
                <Editor
                  editorStyle={{ minHeight: "300px" }}
                  editorState={shortText}
                  onEditorStateChange={(s) => {
                    setShortText(s);
                  }}
                  placeholder="Write something!"
                />
              </div>
              <div className="card-footer text-right">
                <button
                  onClick={() => updateShort()}
                  type="button"
                  className="btn btn-danger btn-flat "
                >
                  Save
                </button>
              </div>
              {loadingShortText ? (
                <div className="overlay">
                  <i className="fa fa-spinner fa-spin"></i>
                </div>
              ) : undefined}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
