import { ISlideBanner, ISlideBannerAddPayload, ISlideBannerOrderUpdatePayload } from "../types/slider-banner-type";
import * as base from "./base-api-service";

/**
 * Get list of slide banners
 */
export const getAll: () => Promise<ISlideBanner[]> = () => {  
  return base.get("site/getslidebanners");
};


/**
 * Add new banner
 * @param data Payload
 * @returns 
 */
export const add: (data: ISlideBannerAddPayload) => Promise<ISlideBanner> = (
  data: ISlideBannerAddPayload
) => {
  let fd = new FormData();
  for (let key in data as any) {
    fd.append(key, (data as any)[key]);
  }
  return base.post("site/addslidebanner", fd);
};

/**
 * Update slide order
 * @param data Payload
 * @returns 
 */
export const update: (data: ISlideBannerOrderUpdatePayload) => Promise<ISlideBanner[]> = (
  data: ISlideBannerOrderUpdatePayload
) => {
  return base.patch("site/updateslideorder", data);
};

/**
 * Delete banner
 * @param id banner Id
 * @returns void
 */
export const del: (id: number) => Promise<void> = (id: number) => {
  return base.del("site/deleteslidebanner", {
    id,
  });
};
