import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as API from "../AAL/finish-type-api-service";
import { IFinishType, IFinishTypeCreatePayload, IFinishTypeUpdatePayload } from "../types/finish-type-type";

// Define a type for the slice state
interface DefaultState {
  finishTypes: IFinishType[];
}

// Define the initial state using that type
const initialState: DefaultState = {
  finishTypes: [],
};

//#region
//Get All FinishTypes
const fetch = createAsyncThunk("finishtype/fetch", async () => {
  let data = await API.getAll();
  return data;
});

//Add FinishType
const add = createAsyncThunk(
  "finishtype/add",
  async (payload: IFinishTypeCreatePayload, thunkAPI) => {
    let data = await API.add(payload);
    return data;
  }
);

//Update FinishType
const update = createAsyncThunk(
  "finishtype/update",
  async (payload: IFinishTypeUpdatePayload, thunkAPI) => {
    let data = await API.update(payload);
    return data;
  }
);

//remove color
const remove = createAsyncThunk(
  "finishtype/remove",
  async (payload: number, thunkAPI) => {
    await API.del(payload);
    return payload;
  }
);
//#endregion

export const finishTypeSlice = createSlice({
  name: "finishtype",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetch.fulfilled, (s, a) => {
        s.finishTypes = a.payload;
      })
      .addCase(add.fulfilled, (s, a) => {
        s.finishTypes.push(a.payload);
      })
      .addCase(update.fulfilled, (s, a) => {
        let c = s.finishTypes.find((x) => x.id === a.payload.id);
        if (c) {
          c.finish = a.payload.finish;
        }
      })
      .addCase(remove.fulfilled, (s,a)=> {
        let cat = s.finishTypes.find(x=> x.id === a.payload);
        if(cat){
          let index = s.finishTypes.indexOf(cat);
          if(index>-1){
            s.finishTypes.splice(index,1);
          }
        }
      })
  },
});

export { update, add, fetch, remove };
export default finishTypeSlice.reducer;
