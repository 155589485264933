import { IAboutUsBannerUpdatePayload, IAboutUsTextUpdatePayload } from "../types/about-us-type";
import * as base from "./base-api-service";

/**
 * Get About Us Text (long)
 */
export const getLongText: () => Promise<string> = () => {  
  return base.get("site/getaboutustext");
};


/**
 * Get About Us Text (short)
 */
 export const getShortText: () => Promise<string> = () => {  
  return base.get("site/getaboutusshorttext");
};

/**
 * Get About Us banner
 */
 export const getBanner: () => Promise<string> = () => {  
  return base.get("site/getaboutusbanner");
};

/**
 * Update About us text (long)
 * @param data Payload
 */
export const updateLongText: (data: IAboutUsTextUpdatePayload) => Promise<void> = (
  data: IAboutUsTextUpdatePayload
) => {
  return base.patch("site/updateaboutustext", data);
};

/**
 * Update About us text (short)
 * @param data Payload
 */
 export const updateShortText: (data: IAboutUsTextUpdatePayload) => Promise<void> = (
  data: IAboutUsTextUpdatePayload
) => {
  return base.patch("site/updateaboutusshorttext", data);
};

/**
 * Update About us text (short)
 * @param data Payload
 */
 export const updateBanner: (data: IAboutUsBannerUpdatePayload) => Promise<string> = (
  data: IAboutUsBannerUpdatePayload
) => {
  let fd = new FormData();
  fd.append("photo", data.photo);
  return base.patch("site/updateaboutusbanner", fd);
};

/**
 * clear about us text (long)
 */
export const clearLongText: () => Promise<void> = () => {
  return base.del("site/deleteaboutustext",{});
};

/**
 * clear about us text (short)
 */
 export const clearShortText: () => Promise<void> = () => {
  return base.del("site/deleteaboutusshorttext", {});
};

/**
 * delete about us banner
 */
 export const deleteAboutUsBanner: () => Promise<void> = () => {
  return base.del("site/deleteaboutusbanner", {});
};
