import { IAddItemToQuotation, ICreateQuotation, IQuotation, IQuotationItem, IUpdateQuotation, IUpdateQuotationItem } from '../types/quotation-type';
import * as base from './base-api-service';

/**
 * Get All Quoations
 * @returns List of Quotations
 */
export const getAllQuotation: ()=> Promise<IQuotation[]> = ()=> {
    return  base.get("quotation/getall");
}

/**
 * Get  Quoations
 * @returns Quotations
 */
 export const getQuotation: (id: number)=> Promise<IQuotation> = (id: number)=> {
    return  base.get("quotation/get",{
        id
    });
}

/**
 * Create new quotation
 * @param data Crate Quotation Payload
 * @returns Quotation
 */
export const createQuotation: (data: ICreateQuotation)=> Promise<IQuotation> = (data: ICreateQuotation)=> {
    return base.post("quotation/create",data);
}

/**
 * Update Quotation
 * @param data Update Quotation Payload
 * @returns Updated Quotation
 */
export const updateQuotation: (data: IUpdateQuotation)=> Promise<IUpdateQuotation> = (data: IUpdateQuotation)=> {
    return base.patch("quotation/update", data);
}

/**
 * Delete Quotation
 * @param id Quotation Id
 */
export const deleteQuotation: (id: number)=> Promise<void> = (id:number)=> {
    return base.del("quotation/deletequotation",{
        id
    });
}

/**
 * Add Item to Quotation
 * @param data Add Item Payload
 * @returns Added Item
 */
export const addItemToQuotation: (data: IAddItemToQuotation)=> Promise<IQuotationItem> = (data: IAddItemToQuotation)=> {
    return base.post("quotation/additem", data);
}

/**
 * Update Item
 * @param data Update Item Payload
 * @returns Update item
 */
export const updateQuotationItem: (data: IUpdateQuotationItem)=> Promise<IUpdateQuotationItem> = (data: IUpdateQuotationItem)=> {
    return base.patch("quotation/updateitem", data);
}

/**
 * Delete Item from quotation
 * @param id Item Id
  */
export const deleteQuotationItem: (id:number)=> Promise<void> = (id: number)=> {
    return base.del("quotation/deleteitem", {
        id
    });
}