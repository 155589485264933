import { useEffect, useState } from "react";
import { Button, Form, FormGroup, FormLabel, Modal } from "react-bootstrap";
import { mySwal } from "../../config/sw-config";
import { HandleException } from "../../helpers/exception-handler";
import { useAppDispatch } from "../../hooks/store-hooks";
import { update } from "../../slices/color-slice";
import { IColor } from "../../types/color-type";

type TProps = {
  visible: boolean;
  onClose: () => void;
  onUpdated?: () => void;
  color: IColor;
};

export default function UpdateColor(props: TProps) {
  //form values
  const [name, setName] = useState("");
  const [id, setId] = useState<number>();
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setName(props.color.colorName);
    setId(props.color.id);
  }, [props.color]);

  const updateColor = async () => {
    if (!id) {
      return;
    }
    if (!name) {
      mySwal.fire({
        titleText: "",
        text: "Please enter color name.",
        timer: 2000,
        icon: "error",
      });
      return;
    }

    try {
      setLoading(true);
      await dispatch(
        update({
          colorName: name,
          id,
        })
      ).unwrap();
      mySwal.fire({
        titleText: "Updated",
        text: "Color Updated Successfully.",
        icon: "success",
        timer: 2000,
        toast: true,
      });
      if (props.onUpdated) {
        props.onUpdated();
      }
    } catch (error) {
      HandleException(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      show={props.visible}
      onHide={() => {
        if (props.onClose) {
          props.onClose();
        }
      }}
    >
      <Form
        onSubmit={(e) => {
          updateColor();
          e.preventDefault();
        }}
      >
        <Modal.Header closeButton closeLabel="hello">
          <Modal.Title>Update Color</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            <FormLabel>Color Name</FormLabel>
            <input
              onChange={(e) => setName(e.target.value)}
              autoFocus
              value={name}
              type="text"
              className="form-control"
            />
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={loading}
            type="submit"
            variant="primary"
            className="btn-flat"
            onClick={() => {}}
          >
            {loading ? "Please wait..." : "Update"}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
