import { createContext } from "react";
import { ImageBaseUrl, VideoBaseUrl } from "../config/api-config";
import { useAppSelector } from "../hooks/store-hooks";
import { userInfo } from "../slices/auth-slice";

type TAppContext = {
  getImage?: (image: string) => string;
  getVideo?: (video: string) => string;
};

const appContext = createContext({
  getImage: undefined,
  getVideo: undefined,
} as TAppContext);

const AppContextProvider = ({ children }: any) => {
  const user = useAppSelector(userInfo);
  return (
    <appContext.Provider
      value={{
        getImage: (img: string) => {
          let url = ImageBaseUrl + img + "&token=" + user?.token;
          return url;
        },
        getVideo: (video: string) => {
          let url = VideoBaseUrl + video + "&token=" + user?.token;
          return url;
        },
      }}
    >
      {children}
    </appContext.Provider>
  );
};

export { appContext, AppContextProvider };
