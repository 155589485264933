import React, { useContext, useEffect, useRef, useState } from "react";
import { mySwal } from "../../config/sw-config";
import { HandleException } from "../../helpers/exception-handler";
import { get, update } from "../../AAL/team-members-api-service";
import { FormGroup } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { ITeamMember } from "../../types/team-member-type";
import { appContext } from "../../context/app-context";
export default function EditMember() {
  const { getImage } = useContext(appContext);
  const { id } = useParams();
  const [member, setMember] = useState<ITeamMember>();
  const [name, setName] = useState("");
  const [designation, setDesignation] = useState("");
  const [photo, setPhoto] = useState<File>();
  const [photoUrl, setPhotoUrl] = useState("");

  const nameInputRef = useRef<HTMLInputElement>(null);
  const designationInputRef = useRef<HTMLInputElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);
        let data = await get(+id!);
        setMember(data);
        setLoading(false);
      } catch (error) {
        HandleException(error);
      }
    }
    loadData();
  }, [id]);

  useEffect(() => {
    if (member) {
      setName(member?.name);
      setDesignation(member?.designation);
      setPhotoUrl(getImage!(member.photo));
    }
  }, [member, getImage]);

  const updateMember = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!name) {
      mySwal.fire({
        text: "Please enter name",
        icon: "error",
        toast: true,
        timer: 2000,
      });
      nameInputRef.current?.focus();
      return;
    }

    if (!designation) {
      mySwal.fire({
        text: "Please enter designation.",
        icon: "error",
        toast: true,
        timer: 2000,
      });
      designationInputRef.current?.focus();
      return;
    }

    try {
      setLoading(true);
      let data = await update({
        id: member!.id,
        name,
        designation,
        photo,
      });
      mySwal.fire({
        titleText: "Member updated successfully.",
        toast: true,
        icon: "success",
        timer: 2000,
      });
      if (data) {
        setMember(data);
      }
      setPhoto(undefined);
      fileInputRef!.current!.value = "";
      nameInputRef.current?.focus();
    } catch (error) {
      HandleException(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <section className="content-header">
        <Link to="../team-members" className="btn btn-warning btn-flat btn-sm">
          <i className="fa fa-arrow-left"></i> Back To List
        </Link>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-md-4">
            <form onSubmit={updateMember}>
              <div className="card card-danger card-outline">
                <div className="card-header with-border">
                  <h4 className="card-title">Add New Team Member</h4>
                </div>
                <div className="card-body">
                  <FormGroup>
                    <label>Name</label>
                    <input
                      ref={nameInputRef}
                      type="text"
                      className="form-control"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </FormGroup>

                  <FormGroup>
                    <label>Designation</label>
                    <input
                      ref={designationInputRef}
                      type="text"
                      className="form-control"
                      value={designation}
                      onChange={(e) => setDesignation(e.target.value)}
                    />
                  </FormGroup>

                  <FormGroup>
                    <img
                      className="img-thumbnail"
                      style={{
                        height: "120px",
                      }}
                      src={photoUrl}
                      alt=""
                    />
                  </FormGroup>

                  <FormGroup>
                    <label>Photo</label>
                    <input
                      ref={fileInputRef}
                      type="file"
                      accept="image/*"
                      className="form-control"
                      onChange={(e) => {
                        if (e.target.files?.length) {
                          setPhoto(e.target.files[0]);
                          setPhotoUrl(URL.createObjectURL(e.target.files[0]));
                        }
                      }}
                    />
                  </FormGroup>
                </div>
                <div className="card-footer text-right">
                  <input
                    type={"submit"}
                    className="btn btn-danger btn-flat"
                    value="Add"
                  />
                </div>
                {loading ? (
                  <div className="overlay">
                    <i className="fa fa-spinner fa-spin"></i>
                  </div>
                ) : undefined}
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}
