import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { HandleException } from "../../helpers/exception-handler";
import { useAppDispatch, useAppSelector } from "../../hooks/store-hooks";
import { logout, userInfo } from "../../slices/auth-slice";

export function Navbar() {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const dispatch = useAppDispatch();
  const user = useAppSelector(userInfo);
  const navigate = useNavigate();
  return (
    <nav className="main-header navbar navbar-expand navbar-white navbar-light">
      <ul className="navbar-nav">
        <li className="nav-item">
          <a
            className="nav-link"
            data-widget="pushmenu"
            role="button"
            onClick={() => {
              if (sidebarOpen) {
                document.body.classList.add("sidebar-collapse");
              } else {
                document.body.classList.remove("sidebar-collapse");
              }
              setSidebarOpen(!sidebarOpen);
            }}
          >
            <i className="fas fa-bars"></i>
          </a>
        </li>
      </ul>
      <ul className="navbar-nav ml-auto">
        <li className="nav-item">
          <Link className="nav-link" to="">
            <i className="fas fa-user"></i>
          </Link>
        </li>
        <li className="nav-item">
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
            }}
            onClick={async () => {
              try {
                await dispatch(logout());
                navigate("/login");
              } catch (error) {
                HandleException(error);
              }
            }}
            className="nav-link"
          >
            <i className="fas fa-sign-out-alt"></i>
          </button>
        </li>
      </ul>
    </nav>
  );
}
