import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { ICategoryCreatePayload, ICategory, ICategoryUpdatePayload } from "../types/category-type";
import * as CategoryAPI from "../AAL/category-api-service";

// Define a type for the slice state
interface CategoryState {
  categories: ICategory[];
}

// Define the initial state using that type
const initialState: CategoryState = {
  categories: [],
};

//#region
//Get All Categories
const fetch = createAsyncThunk("category/fetch", async () => {
  let data = await CategoryAPI.getAll();
  return data;
});

//Add Category
const add = createAsyncThunk(
  "category/add",
  async (payload: ICategoryCreatePayload, thunkAPI) => {
    let data = await CategoryAPI.add(payload);
    return data;
  }
);

//Update Category
const update = createAsyncThunk(
  "category/update",
  async (payload: ICategoryUpdatePayload, thunkAPI) => {
    let data = await CategoryAPI.update(payload);
    return data;
  }
);

//Update Category
const remove = createAsyncThunk(
  "category/remove",
  async (payload: number, thunkAPI) => {
    await CategoryAPI.del(payload);
    return payload;
  }
);
//#endregion

export const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetch.fulfilled, (s, a) => {
        s.categories = a.payload;
      })
      .addCase(add.fulfilled, (s, a) => {
        s.categories.push(a.payload);
      })
      .addCase(update.fulfilled, (s, a) => {
        let c = s.categories.find((x) => x.id === a.payload.id);
        if (c) {
          c.name = a.payload.name;
          c.thumbnail = a.payload.thumbnail;
        }
      })
      .addCase(remove.fulfilled, (s,a)=> {
        let cat = s.categories.find(x=> x.id === a.payload);
        if(cat){
          let index = s.categories.indexOf(cat);
          if(index>-1){
            s.categories.splice(index,1);
          }
        }
      })
  },
});

export { update, add, fetch, remove };
export const categories = (state: RootState) => state.category.categories;

export default categorySlice.reducer;
