import { IColor, IColorCreatePayload, IColorUpdatePayload } from "../types/color-type";
import * as base from "./base-api-service";

/**
 * Get list of colors
 */
export const getAll: () => Promise<IColor[]> = () => {  
  return base.get("color/getall");
};

/**
 * Get color by id
 * @param id color id
 * @returns Color
 */
export const get: (id: number) => Promise<IColor> = (id: number) => {
  return base.get("color/get", {
    id,
  });
};

/**
 * Add new color
 * @param data Payload
 * @returns color
 */
export const add: (data: IColorCreatePayload) => Promise<IColor> = (
  data: IColorCreatePayload
) => {
  return base.post("color/add", data);
};

/**
 * Update color
 * @param data Payload
 * @returns color
 */
export const update: (data: IColorUpdatePayload) => Promise<IColor> = (
  data: IColorUpdatePayload
) => {
  return base.patch("color/update", data);
};

/**
 * Delete color
 * @param id color Id
 * @returns void
 */
export const del: (id: number) => Promise<void> = (id: number) => {
  return base.del("color/delete", {
    id,
  });
};
