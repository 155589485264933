import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  getAllQuotation,
  deleteQuotation,
} from "../../AAL/quotation-api-service";
import { mySwal } from "../../config/sw-config";
import { HandleException } from "../../helpers/exception-handler";
import { IQuotation } from "../../types/quotation-type";

export function Quotations() {
  const [quotations, setQuotations] = useState<IQuotation[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function load() {
      try {
        var res = await getAllQuotation();
        setQuotations(res);
      } catch (error) {
        HandleException(error);
      } finally {
        setLoading(false);
      }
    }
    load();
  }, []);

  const deleteQuot = async (id: number) => {
    mySwal
      .fire({
        titleText: "Confirm",
        text: "Are you sure?",
        icon: "question",
        showCancelButton: true,
      })
      .then(async (status) => {
        if (status.isConfirmed) {
          try {
            setLoading(true);
            await deleteQuotation(id);
            mySwal.fire({
              titleText: "Deleted",
              text: "Quotation Deleted.",
              timer: 2000,
              icon: "success",
            });
            let item = quotations.find((x) => x.id === id);
            if (item != null) {
              let index = quotations.indexOf(item);
              quotations.splice(index, 1);
              setQuotations(quotations);
            }
          } catch (error) {
            HandleException(error);
          } finally {
            setLoading(false);
          }
        }
      });
  };

  return (
    <>
      <section className="content-header"></section>
      <section className="content">
        <div className="card card-outline card-danger">
          <div className="card-header with-border">
            <h4 className="card-title">Quotations</h4>
            <div className="card-tools">
              <Link
                to="../create-quotation"
                className="btn btn-primary btn-flat btn-sm"
              >
                Create New
              </Link>
            </div>
          </div>
          <div className="card-body">
            <table className="table">
              <thead>
                <tr>
                  <th>Q.No.</th>
                  <th>Client</th>
                  <th>Email</th>
                  <th>Date</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {quotations.map((q, i) => (
                  <tr key={i}>
                    <td>{q.id}</td>
                    <td>{q.to}</td>
                    <td>{q.email}</td>
                    <td>{q.dateCreated.toDateString()}</td>
                    <td>
                      <Link
                        title="Manage Items"
                        className="btn btn-success btn-sm btn-flat mr-2"
                        to={"../quotation-items/" + q.id}
                      >
                        <i className="fa fa-list" />
                      </Link>

                      <Link
                        title="Edit"
                        className="btn btn-primary btn-sm btn-flat mr-2"
                        to={"../update-quotation/" + q.id}
                      >
                        <i className="fa fa-edit" />
                      </Link>

                      <button
                        onClick={() => {
                          deleteQuot(q.id);
                        }}
                        title="Delete"
                        type="button"
                        className="btn btn-danger btn-sm btn-flat mr-2"
                      >
                        <i className="fa fa-trash" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {loading ? (
            <div className="overlay">
              <i className="fa fa-spinner fa-spin"></i>
            </div>
          ) : undefined}
        </div>
      </section>
    </>
  );
}
