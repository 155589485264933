import { ICollection, ICollectionCreatePayload, ICollectionToggleProduct, ICollectionUpdatePayload } from "../types/collection-type";
import { IProduct } from "../types/product-type";
import * as base from "./base-api-service";

/**
 * Get list of collections
 */
export const getAll: () => Promise<ICollection[]> = () => {  
  return base.get("collection/getall");
};

/**
 * Get collection  by id
 * @param id Collection id
 * @returns Collection
 */
export const get: (id: number) => Promise<ICollection> = (id: number) => {
  return base.get("collection/get", {
    id,
  });
};

/**
 * Add new collection
 * @param data Payload
 * @returns Collection
 */
export const add: (data: ICollectionCreatePayload) => Promise<ICollection> = (
  data: ICollectionCreatePayload
) => {
  var fd = new FormData();
  for(let key in (data as any)) {
    fd.append(key,(data as any)[key]);
  }
  return base.post("collection/add", fd);
};

/**
 * Update collection
 * @param data Payload
 * @returns Collection
 */
export const update: (data: ICollectionUpdatePayload) => Promise<ICollection> = (
  data: ICollectionUpdatePayload
) => {
  var fd = new FormData();
  for(let key in (data as any)) {
    fd.append(key,(data as any)[key]);
  }
  return base.patch("collection/update", fd);
};

/**
 * Delete Collection
 * @param id Collection Id
 * @returns void
 */
export const del: (id: number) => Promise<void> = (id: number) => {
  return base.del("collection/delete", {
    id,
  });
};

/**
 * Add product to  collection
 * @param data Payload
 */
 export const addToCollection: (data: ICollectionToggleProduct) => Promise<IProduct> = (
  data: ICollectionToggleProduct
) => {
  return base.post("collection/addproducttocollection", data);
};

/**
 * remove product to  collection
 * @param data Payload
 */
 export const removeFromCollection: (data: ICollectionToggleProduct) => Promise<void> = (
  data: ICollectionToggleProduct
) => {
  return base.post("collection/removeproductfromcollection", data);
};