import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import * as AuthAPI from "../AAL/auth-api-service";
import { ILoginRequest, ILoginResponse, ILogoutRequest } from "../types/auth-type";
import {persistConfig } from "../config/config";
import { persistReducer } from "redux-persist";

// Define a type for the slice state
interface CategoryState {
  userInfo?: ILoginResponse,
  isAuthed: boolean
}

// Define the initial state using that type
const initialState: CategoryState = {
  userInfo: undefined,
  isAuthed: false
};

//#region
//Login
const login = createAsyncThunk("auth/login", async (payload:ILoginRequest,thunkAPI) => {  
    let data = await AuthAPI.login(payload);
    return data;
});

//#endregion

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout:(s)=> {
      s.isAuthed = false;
      s.userInfo = undefined;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (s, a) => {
        s.userInfo = a.payload;
        s.isAuthed = true;
      })
  },
});

export { login };
export const userInfo = (state: RootState) => state.auth.userInfo;
export const isAuthed = (state: RootState) => state.auth.isAuthed;
export const {logout} = authSlice.actions;
let persist = persistReducer(persistConfig,authSlice.reducer);
export default persist;
