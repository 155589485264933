import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { del, getAll } from "../../AAL/enquiry-api-service";
import { mySwal } from "../../config/sw-config";
import { HandleException } from "../../helpers/exception-handler";
import { IEnquiry } from "../../types/enquiry-type";
import MRT, { MRT_ColumnDef } from "material-react-table";

export default function Enquiries() {
    const [enquiries, setEnquiries] = useState<IEnquiry[]>([]);
    const [searchText, setSearchText] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function loadData() {
            try {
                setLoading(true);
                let enqs = await getAll();
                setEnquiries(enqs);
            } catch (error) {
                HandleException(error);
            } finally {
                setLoading(false);
            }
        }
        loadData();
    }, []);

    //Delete
    const deleteEnquiry =useCallback(async (id: number) => {
        const res = await mySwal.fire({
            titleText: "Confirm",
            text: "Are you sure?",
            showCancelButton: true,
            icon: "warning",
            confirmButtonText: "Delete",
        });
        if (res.isConfirmed) {
            try {
                setLoading(true);
                await del(id);
                let tmp = [...enquiries];
                console.log(tmp);
                let index = tmp.findIndex((x) => x.id === id);
                console.log(index, id);
                if (index >= 0) {
                    tmp.splice(index, 1);
                    setEnquiries([...tmp]);
                }
                mySwal.fire({
                    title: "Deleted",
                    text: "Enquiry deleted successfully.",
                    toast: true,
                    icon: "success",
                });
            } catch (error) {
                HandleException(error);
            } finally {
                setLoading(false);
            }
        }
    },[enquiries]);

    //table columns
    const columns = useMemo(
        () =>
            [
                {
                    accessorKey: "dateCreated",
                    header: "Date",
                    Cell: ({ row }) =>
                        new Date(row.original.dateCreated).toLocaleString(),
                },
                {
                    accessorKey: "name",
                    header: "Name",
                },
                {
                    accessorKey: "email",
                    header: "Email",
                },
                {
                    accessorKey: "subject",
                    header: "Subject",
                },
                {
                    accessorKey: "isReaded",
                    header: "Resolved",
                    Cell: (props) =>
                        props.row.original.isReaded ? "Yes" : "No",
                },
                {
                    id: "actions",
                    header: "",
                    Cell: ({ row }) => (
                        <div>
                            <Link
                                to={"../enquiries/" + row.original.id}
                                className="btn btn-flat btn-sm btn-info"
                                title="View"
                            >
                                <i className="fa fa-eye"></i>
                            </Link>
                            <button
                                onClick={() => {
                                    deleteEnquiry(row.original.id);
                                }}
                                className="btn btn-danger btn-flat btn-sm ml-2"
                                title="Delete"
                            >
                                <i className="fa fa-trash"></i>
                            </button>
                        </div>
                    ),
                },
            ] as MRT_ColumnDef<IEnquiry>[],
        [deleteEnquiry]
    );
    return (
        <>
            <section className="content-header"></section>
            <section className="content">
                <div className="card card-outline card-danger">
                    <div className="card-header with-border">
                        <h4 className="card-title">Manage Enquiries</h4>
                    </div>
                    <div className="card-body">
                        <MRT data={enquiries} columns={columns} />
                    </div>
                    {loading ? (
                        <div className="overlay">
                            <i className="fa fa-spinner fa-spin"></i>
                        </div>
                    ) : undefined}
                </div>
            </section>
        </>
    );
}
