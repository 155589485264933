import React, { useRef, useState } from "react";
import { mySwal } from "../../config/sw-config";
import { HandleException } from "../../helpers/exception-handler";
import { add } from "../../AAL/team-members-api-service";
import { FormGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
export default function AddMember() {
  const [name, setName] = useState("");
  const [designation, setDesignation] = useState("");
  const [photo, setPhoto] = useState<File>();

  const nameInputRef = useRef<HTMLInputElement>(null);
  const designationInputRef = useRef<HTMLInputElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [loading, setLoading] = useState(false);

  const addNew = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!name) {
      mySwal.fire({
        text: "Please enter name",
        icon: "error",
        toast: true,
        timer: 2000,
      });
      nameInputRef.current?.focus();
      return;
    }

    if (!designation) {
      mySwal.fire({
        text: "Please enter designation.",
        icon: "error",
        toast: true,
        timer: 2000,
      });
      designationInputRef.current?.focus();
      return;
    }

    if (!photo) {
      mySwal.fire({
        text: "Please select photo",
        icon: "error",
        toast: true,
        timer: 2000,
      });
      fileInputRef.current?.focus();
      return;
    }
    try {
      setLoading(true);
      await add({
        name,
        designation,
        photo,
      });
      mySwal.fire({
        titleText: "Member added successfully.",
        toast: true,
        icon: "success",
        timer: 2000,
      });
      setName("");
      setDesignation("");
      setPhoto(undefined);
      fileInputRef!.current!.value = "";
      nameInputRef.current?.focus();
    } catch (error) {
      HandleException(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <section className="content-header">
        <Link to="../team-members" className="btn btn-warning btn-flat btn-sm">
          <i className="fa fa-arrow-left"></i> Back To List
        </Link>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-md-4">
            <form onSubmit={addNew}>
              <div className="card card-danger card-outline">
                <div className="card-header with-border">
                  <h4 className="card-title">Add New Team Member</h4>
                </div>
                <div className="card-body">
                  <FormGroup>
                    <label>Name</label>
                    <input
                      ref={nameInputRef}
                      type="text"
                      className="form-control"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </FormGroup>

                  <FormGroup>
                    <label>Designation</label>
                    <input
                      ref={designationInputRef}
                      type="text"
                      className="form-control"
                      value={designation}
                      onChange={(e) => setDesignation(e.target.value)}
                    />
                  </FormGroup>

                  <FormGroup>
                    <label>
                      Photo<small> (best 1:1 or 2:3)</small>
                    </label>
                    <input
                      ref={fileInputRef}
                      type="file"
                      accept="image/*"
                      className="form-control"
                      onChange={(e) => {
                        if (e.target.files?.length) {
                          setPhoto(e.target.files[0]);
                        }
                      }}
                    />
                  </FormGroup>
                </div>
                <div className="card-footer text-right">
                  <input
                    type={"submit"}
                    className="btn btn-danger btn-flat"
                    value="Add"
                  />
                </div>
                {loading ? (
                  <div className="overlay">
                    <i className="fa fa-spinner fa-spin"></i>
                  </div>
                ) : undefined}
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}
