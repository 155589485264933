import { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import AddCategory from "../../components/category/add-category";
import UpdateCategory from "../../components/category/update-category";
import { mySwal } from "../../config/sw-config";
import { HandleException } from "../../helpers/exception-handler";
import { useAppDispatch, useAppSelector } from "../../hooks/store-hooks";
import { fetch, remove } from "../../slices/category-slice";
import { ICategory } from "../../types/category-type";

export function Categories() {
  const categories = useAppSelector((s) => s.category.categories);
  const dispatch = useAppDispatch();

  const [visibleAddCategory, setVisibleAddCategory] = useState(false);
  const [visibleUpdateCategory, setVisibleUpdateCategory] = useState(false);

  const [catToUpdate, setCatToUpdate] = useState<ICategory | null>(null);

  //on mound
  useEffect(() => {
    async function loadCategories() {
      try {
        await dispatch(fetch()).unwrap();
      } catch (error) {
        HandleException(error);
      }
    }
    loadCategories();
  }, [dispatch]);

  return (
    <>
      <section className="content-header"></section>
      <section className="content">
        <div className="container-fluid">
          <div className="card card-outline card-danger">
            <div className="card-header with-border">
              <h4 className="card-title">Categories</h4>
              <div className="card-tools">
                <Button
                  onClick={() => {
                    setVisibleAddCategory(true);
                  }}
                  size="sm"
                >
                  Add New
                </Button>
              </div>
            </div>
            <div className="card-body">
              <Table>
                <thead>
                  <tr>
                    <th>Category</th>
                  </tr>
                </thead>
                <tbody>
                  {categories.map((c, i) => (
                    <tr key={i}>
                      <td>{c.name}</td>
                      <td>
                        <Button
                          className="m-2 btn-flat"
                          onClick={() => {
                            setCatToUpdate(c);
                            setVisibleUpdateCategory(true);
                          }}
                          title="Edit"
                        >
                          <i className="fa  fa-edit" />
                        </Button>
                        <Button
                          variant="danger"
                          className="btn-flat"
                          title="Delete"
                          onClick={() => {
                            mySwal
                              .fire(
                                "Confirm",
                                "All products related to this category will  also be removed. Are you sure?",
                                "warning"
                              )
                              .then(
                                async () => {
                                  await dispatch(remove(c.id));
                                  mySwal.fire({
                                    text: "Category Deleted.",
                                    icon: "success",
                                    timer: 1000,
                                  });
                                },
                                () => {}
                              );
                          }}
                        >
                          <i className="fa  fa-trash" />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </section>
      <AddCategory
        visible={visibleAddCategory}
        onClose={() => {
          setVisibleAddCategory(false);
        }}
        onAdded={() => {
          setVisibleAddCategory(false);
        }}
      />

      {catToUpdate ? (
        <UpdateCategory
          category={catToUpdate!}
          visible={true}
          onClose={() => {
            setCatToUpdate(null);
          }}
          onUpdated={() => {
            setCatToUpdate(null);
          }}
        />
      ) : undefined}
    </>
  );
}
