import { useEffect, useState } from "react";
import { FormGroup } from "react-bootstrap";
import { add, del, getAll } from "../../AAL/featured-product-api-service";
import { HandleException } from "../../helpers/exception-handler";
import { useAppDispatch, useAppSelector } from "../../hooks/store-hooks";
import { fetch } from "../../slices/product-slice";
import { IFeaturedProduct } from "../../types/featured-product-type";

export default function FeaturedProducts() {
  const dispatch = useAppDispatch();
  const products = useAppSelector((s) => s.product.products);
  const [pSearchText, setPSearchText] = useState("");
  const [fSearchText, setFSearchText] = useState("");
  const [featured, setFeatured] = useState<IFeaturedProduct[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadData() {
      try {
        if (!products?.length) {
          await dispatch(fetch()).unwrap();
        }
        let featured = await getAll();
        if (featured?.length) {
          setFeatured(featured);
        }
      } catch (error) {
        HandleException(error);
      } finally {
        setLoading(false);
      }
    }
    loadData();
  }, []);

  // add to list
  const addToList = async (id: number) => {
    try {
      setLoading(true);
      let f = await add({
        productId: id,
      });
      setFeatured([...featured, f]);
    } catch (error) {
      HandleException(error);
    } finally {
      setLoading(false);
    }
  };

  // add to list
  const removeFromList = async (id: number) => {
    try {
      setLoading(true);
      await del(id);
      let f = featured.find((x) => x.id === id);
      if (f) {
        let index = featured.indexOf(f);
        if (index > -1) {
          featured.splice(index, 1);
          setFeatured([...featured]);
        }
      }
    } catch (error) {
      HandleException(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <section className="content-header"></section>
      <section className="content">
        <div className="row">
          <div className="col-md-6">
            <div className="card card-outline card-danger">
              <div className="card-header with-border">
                <h4 className="card-title">Products</h4>
              </div>
              <div className="card-body">
                <FormGroup>
                  <input
                    value={pSearchText}
                    onChange={(e) => setPSearchText(e.target.value)}
                    type={"search"}
                    placeholder="Search"
                    className="form-control"
                  />
                </FormGroup>
                <table className="table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Category</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {products
                      ?.filter(
                        (p) =>
                          p.name
                            .toLowerCase()
                            .includes(pSearchText?.toLowerCase()) &&
                          !featured.find((x) => x.productId === p.id)
                      )
                      ?.map((p, i) => (
                        <tr>
                          <td>{p.name}</td>
                          <td>{p.category.name}</td>
                          <td>
                            <button
                              onClick={() => addToList(p.id)}
                              type="button"
                              title="Add To Featured List"
                              className="btn btn-sm btn-flat btn-info"
                            >
                              <i className="fas fa-angle-double-right"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              {loading ? (
                <div className="overlay">
                  <i className="fa fa-spinner fa-spin"></i>
                </div>
              ) : undefined}
            </div>
          </div>
          <div className="col-md-6">
            <div className="card card-outline card-danger">
              <div className="card-header with-border">
                <h4 className="card-title">Featured List</h4>
              </div>
              <div className="card-body">
                <FormGroup>
                  <input
                    value={fSearchText}
                    onChange={(e) => setFSearchText(e.target.value)}
                    type={"search"}
                    placeholder="Search"
                    className="form-control"
                  />
                </FormGroup>
                <table className="table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Category</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {featured.map((f, i) => {
                      let p = products
                        ?.filter((x) =>
                          x.name
                            .toLowerCase()
                            .includes(fSearchText.toLowerCase())
                        )
                        ?.find((x) => x.id === f.productId);
                      if (p) {
                        return (
                          <tr>
                            <td>{p.name}</td>
                            <td>{p.category.name}</td>
                            <td>
                              <button
                                onClick={() => removeFromList(f.id)}
                                title="Remove From List"
                                type="button"
                                className="btn btn-sm btn-flat btn-danger"
                              >
                                <i className="fa fa-trash"></i>
                              </button>
                            </td>
                          </tr>
                        );
                      } else {
                        return undefined;
                      }
                    })}
                  </tbody>
                </table>
              </div>

              {loading ? (
                <div className="overlay">
                  <i className="fa fa-spinner fa-spin"></i>
                </div>
              ) : undefined}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
