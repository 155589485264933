import { AxiosResponse } from "axios";
import { client } from "../config/api-config";

export const post = async <T>(api: string, data: any, config?: any) => {
  try {
    let response: AxiosResponse<T>;
    if (config) {
      response = await client.post<T>(api, data, config);
    } else {
      response = await client.post<T>(api, data);
    }
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const patch = async <T>(api: string, data: any, config?: any) => {
  try {
    let response: AxiosResponse<T>;
    if (config) {
      response = await client.patch<T>(api, data, config);
    } else {
      response = await client.patch<T>(api, data);
    }
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const get = async <T>(api: string, params?: any, config?: any) => {
  try {
    let response: AxiosResponse<T>;
    let url = api;
    if (params) {
      url += "?";
      for (let key in params) {
        url = `${url}${key}=${params[key]}&`;
      }
      url = url.substring(0, url.length - 1);
    }    
    if (config) {
      response = await client.get(url, config);
    } else {
      response = await client.get(url);
    }
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const del = async <T>(api: string, params: any, config?: any) => {
  try {
    let response: AxiosResponse<T>;
    let url = api;
    if (params) {
      url += "?";
      for (let key in params) {
        url = `${url}${key}=${params[key]}&`;
      }
      url = url.substring(0, url.length - 1);
    }
    if (config) {
      response = await client.delete(url, config);
    } else {
      response = await client.delete(url);
    }
    return response.data;
  } catch (error) {
    throw error;
  }
};
