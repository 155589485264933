import { Navigate, useLocation } from "react-router-dom";
import { useAppSelector } from "../../hooks/store-hooks";
import { isAuthed } from "../../slices/auth-slice";

type TProp = {
  children: JSX.Element;
};
export function RequireAuth(props: TProp) {
  const location = useLocation();
  const authed = useAppSelector(isAuthed);
  return authed === true ? (
    props.children
  ) : (
    <Navigate to="/login" replace state={{ path: location.pathname }} />
  );
}
