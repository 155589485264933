import { IAddressUpdatePayload } from "../types/address-type";
import * as base from "./base-api-service";

/**
 * Get Address
 */
export const getAddress: () => Promise<string> = () => {  
  return base.get("site/getaddress");
};

/**
 * Update Address
 * @param data Payload
 */
export const updateAddress: (data: IAddressUpdatePayload) => Promise<void> = (
  data: IAddressUpdatePayload
) => {
  return base.patch("site/updateaddress", data);
};
/**
 * clear address
 */
 export const clearAddress: () => Promise<void> = () => {
  return base.del("site/deleteaddress", {});
};