import React, { useContext, useEffect, useRef, useState } from "react";
import { FormGroup } from "react-bootstrap";
import { add, del, getAll, update } from "../../AAL/slide-banner-api-service";
import { mySwal } from "../../config/sw-config";
import { HandleException } from "../../helpers/exception-handler";
import {
  ISlideBanner,
  ISlideBannerOrderUpdate,
} from "../../types/slider-banner-type";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import { appContext } from "../../context/app-context";

export default function SliderBanners() {
  const { getImage } = useContext(appContext);

  const [banners, setBanners] = useState<ISlideBanner[]>([]);
  const [loading, setLoading] = useState(false);

  //#region form fields
  const [url, setUrl] = useState("");
  const [file, setFile] = useState<File>();
  const fileInputRef = useRef<HTMLInputElement>(null);
  //#endregion

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);
        let bans = await getAll();
        if (bans?.length) {
          setBanners(bans.sort((a, b) => a.order - b.order));
        }
      } catch (error) {
        HandleException(error);
      } finally {
        setLoading(false);
      }
    }
    loadData();
  }, []);

  const addSlide = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!file) {
      mySwal.fire({
        titleText: "Error",
        text: "Please select image file",
        icon: "error",
        toast: true,
        timer: 2000,
      });
      return;
    }
    try {
      setLoading(true);
      let banner = await add({
        url,
        photo: file,
      });
      mySwal.fire({
        titleText: "Added",
        text: "Banner Added Successfully.",
        icon: "success",
        toast: true,
        timer: 2000,
      });
      setFile(undefined);
      fileInputRef!.current!.value = "";
      setBanners([...banners, banner]);
    } catch (error) {
      HandleException(error);
    } finally {
      setLoading(false);
    }
  };

  //#region DnD
  const reorder = (
    list: ISlideBanner[],
    startIndex: number,
    endIndex: number
  ) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const items = reorder(
      banners,
      result.source.index,
      result.destination.index
    );
    updateOrder(items);
    setBanners([...items]);
  };

  //#endregion

  //update order
  const updateOrder = (orderedSlides: ISlideBanner[]) => {
    try {
      let slides: ISlideBannerOrderUpdate[] = [];
      orderedSlides.forEach((b, i) =>
        slides.push({
          slideId: b.id,
          order: i,
        })
      );
      update({ slideOrders: slides });
    } catch (error) {
      HandleException(error);
    }
  };

  ///delete slide
  const deleteSlide = (id: number) => {
    mySwal
      .fire({
        titleText: "Confirm",
        text: "Are you sure?",
        showCancelButton: true,
        confirmButtonText: "Delete",
        icon: "warning",
      })
      .then(async (res) => {
        if (res.isConfirmed) {
          try {
            setLoading(true);
            await del(id);
            let s = banners.find((x) => x.id === id);
            if (s) {
              let index = banners.indexOf(s);
              if (index > -1) {
                banners.splice(index, 1);
                setBanners([...banners]);
              }
            }
          } catch (error) {
            HandleException(error);
          } finally {
            setLoading(false);
          }
        }
      });
  };
  return (
    <>
      <section className="content-header"></section>
      <section className="content">
        <div className="row">
          <div className="col-md-4">
            <form onSubmit={addSlide}>
              <div className="card card-danger card-outline">
                <div className="card-header with-border">
                  <h4 className="card-title">Add New</h4>
                </div>
                <div className="card-body">
                  <FormGroup>
                    <label>
                      Link Url <small>(optional)</small>
                    </label>
                    <input
                      value={url}
                      onChange={(e) => setUrl(e.target.value)}
                      type="text"
                      className="form-control"
                    />
                  </FormGroup>

                  <FormGroup className="mt-3">
                    <label>
                      Image <small>(best 2:1 ratio)</small>
                    </label>
                    <input
                      ref={fileInputRef}
                      type="file"
                      className="form-control"
                      onChange={(e) => {
                        if (e.target.files?.length) {
                          setFile(e.target.files[0]);
                        }
                      }}
                    />
                  </FormGroup>
                </div>
                <div className="card-footer text-right">
                  <input
                    type={"submit"}
                    className="btn btn-flat btn-danger"
                    value="Add"
                  />
                </div>
                {loading ? (
                  <div className="overlay">
                    <i className="fa fa-spinner fa-spin"></i>
                  </div>
                ) : undefined}
              </div>
            </form>
          </div>
          <div className="col-md-8">
            <div className="card card-danger card-outline">
              <div className="card-header with-border">
                <h4 className="card-title">Banner Images</h4>
              </div>
              <div
                className="card-body"
                style={{
                  maxHeight: "500px",
                  overflowY: "scroll",
                }}
              >
                <div className="row">
                  <div className="col-md-6">
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId="id1">
                        {(provided, snapshot) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {banners.map((b, i) => (
                              <Draggable
                                draggableId={"id" + b.id.toString()}
                                key={b.id}
                                index={i}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className="form-group card"
                                  >
                                    <img
                                      src={getImage!(b.photo)}
                                      alt=""
                                      className="img-fluid"
                                    />
                                    {b.url ? (
                                      <div className="m-3">
                                        Link:{" "}
                                        <a
                                          rel="noreferrer"
                                          target={"_blank"}
                                          href={b.url}
                                        >
                                          {b.url}
                                        </a>
                                      </div>
                                    ) : undefined}

                                    <button
                                      onClick={() => deleteSlide(b.id)}
                                      className="btn btn-sm btn-danger btn-flat"
                                    >
                                      <i className="fa fa-trash"></i>
                                    </button>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </div>
                </div>
              </div>
              {loading ? (
                <div className="overlay">
                  <i className="fa fa-spinner fa-spin"></i>
                </div>
              ) : undefined}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
