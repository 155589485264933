import { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import AddMaterialType from "../../components/material-type/add-material-type";
import UpdateMaterialType from "../../components/material-type/update-material-type";
import { mySwal } from "../../config/sw-config";
import { HandleException } from "../../helpers/exception-handler";
import { useAppDispatch, useAppSelector } from "../../hooks/store-hooks";
import { fetch, remove } from "../../slices/material-type-slice";
import { IMaterialType } from "../../types/material-type-type";

export function MaterialTypes() {
  const materialTypes = useAppSelector((s) => s.materialType.materialTypes);
  const dispatch = useAppDispatch();

  const [visibleAddMaterial, setVisibleAddMaterial] = useState(false);
  const [materialToUpdate, setMaterialToUpdate] =
    useState<IMaterialType | null>(null);

  //on mount
  useEffect(() => {
    async function loadMaterialTypes() {
      try {
        await dispatch(fetch()).unwrap();
      } catch (error) {
        HandleException(error);
      }
    }
    loadMaterialTypes();
  }, [dispatch]);

  return (
    <>
      <section className="content-header"></section>
      <section className="content">
        <div className="container-fluid">
          <div className="card card-outline card-danger">
            <div className="card-header with-border">
              <h4 className="card-title">Material Types</h4>
              <div className="card-tools">
                <Button
                  onClick={() => {
                    setVisibleAddMaterial(true);
                  }}
                  size="sm"
                >
                  Add New
                </Button>
              </div>
            </div>
            <div className="card-body">
              <Table>
                <thead>
                  <tr>
                    <th>Material</th>
                  </tr>
                </thead>
                <tbody>
                  {materialTypes.map((c, i) => (
                    <tr key={i}>
                      <td>{c.material}</td>
                      <td>
                        <Button
                          className="m-2 btn-flat"
                          onClick={() => {
                            setMaterialToUpdate(c);
                          }}
                          title="Edit"
                        >
                          <i className="fa  fa-edit" />
                        </Button>
                        <Button
                          variant="danger"
                          className="btn-flat"
                          title="Delete"
                          onClick={() => {
                            mySwal
                              .fire({
                                titleText: "Confirm",
                                text: "All products related to this material will  also be removed. Are you sure?",
                                icon: "warning",
                                confirmButtonText: "Delete",
                                cancelButtonText: "Cancel",
                                showCancelButton: true,
                              })
                              .then(
                                async (result) => {
                                  if (result.isConfirmed) {
                                    await dispatch(remove(c.id));
                                    mySwal.fire({
                                      text: "Material Type Deleted.",
                                      icon: "success",
                                      timer: 1000,
                                      toast: true,
                                      position: "top-right",
                                    });
                                  }
                                },
                                () => {}
                              );
                          }}
                        >
                          <i className="fa  fa-trash" />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </section>
      <AddMaterialType
        visible={visibleAddMaterial}
        onClose={() => {
          setVisibleAddMaterial(false);
        }}
        onAdded={() => {
          setVisibleAddMaterial(false);
        }}
      />

      {materialToUpdate ? (
        <UpdateMaterialType
          materialType={materialToUpdate!}
          visible={true}
          onClose={() => {
            setMaterialToUpdate(null);
          }}
          onUpdated={() => {
            setMaterialToUpdate(null);
          }}
        />
      ) : undefined}
    </>
  );
}
