import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as API from "../AAL/material-type-api-service";
import { IMaterialType, IMaterialTypeCreatePayload, IMaterialTypeUpdatePayload } from "../types/material-type-type";

// Define a type for the slice state
interface DefaultState {
  materialTypes: IMaterialType[];
}

// Define the initial state using that type
const initialState: DefaultState = {
  materialTypes: [],
};

//#region
//Get All MaterialTypes
const fetch = createAsyncThunk("materialtype/fetch", async () => {
  let data = await API.getAll();
  return data;
});

//Add MaterialType
const add = createAsyncThunk(
  "materialtype/add",
  async (payload: IMaterialTypeCreatePayload, thunkAPI) => {
    let data = await API.add(payload);
    return data;
  }
);

//Update MaterialType
const update = createAsyncThunk(
  "materialtype/update",
  async (payload: IMaterialTypeUpdatePayload, thunkAPI) => {
    let data = await API.update(payload);
    return data;
  }
);

//remove MaterialType
const remove = createAsyncThunk(
  "materialtype/remove",
  async (payload: number, thunkAPI) => {
    await API.del(payload);
    return payload;
  }
);
//#endregion

export const materialTypeSlice = createSlice({
  name: "materialtype",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetch.fulfilled, (s, a) => {
        s.materialTypes = a.payload;
      })
      .addCase(add.fulfilled, (s, a) => {
        s.materialTypes.push(a.payload);
      })
      .addCase(update.fulfilled, (s, a) => {
        let c = s.materialTypes.find((x) => x.id === a.payload.id);
        if (c) {
          c.material = a.payload.material;
        }
      })
      .addCase(remove.fulfilled, (s,a)=> {
        let cat = s.materialTypes.find(x=> x.id === a.payload);
        if(cat){
          let index = s.materialTypes.indexOf(cat);
          if(index>-1){
            s.materialTypes.splice(index,1);
          }
        }
      })
  },
});

export { update, add, fetch, remove };
export default materialTypeSlice.reducer;
