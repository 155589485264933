import { useEffect, useRef, useState } from "react";
import { FormGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { add, del, getAll } from "../../AAL/testimonial-api-service";
import { mySwal } from "../../config/sw-config";
import { HandleException } from "../../helpers/exception-handler";
import { ITestimonial } from "../../types/testimonial-type";

export default function Testimonials() {
  const [testimonials, setTestimonials] = useState<ITestimonial[]>([]);

  const [name, setName] = useState("");
  const [message, setMessage] = useState("");

  const nameInputRef = useRef<HTMLInputElement>(null);
  const messageInputRef = useRef<HTMLInputElement>(null);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadData() {
      try {
        let data = await getAll();
        setTestimonials(data);
      } catch (error) {
        HandleException(error);
      }
    }
    loadData();
  }, []);

  const addNew = async () => {
    if (!name) {
      mySwal.fire({
        text: "Please enter name.",
        icon: "error",
        timer: 2000,
        toast: true,
      });
      nameInputRef.current?.focus();
      return;
    }

    if (!message) {
      mySwal.fire({
        text: "Please write message.",
        icon: "error",
        timer: 2000,
        toast: true,
      });
      messageInputRef.current?.focus();
      return;
    }

    try {
      setLoading(true);
      let resp = await add({
        name,
        message,
      });
      setTestimonials([...testimonials, resp]);
      setName("");
      setMessage("");
      mySwal.fire({
        text: "Testimonial added successfully",
        icon: "success",
        timer: 2000,
        toast: true,
      });
    } catch (error) {
      HandleException(error);
    } finally {
      setLoading(false);
    }
  };

  const remove = async (id: number) => {
    mySwal
      .fire({
        titleText: "Confirm",
        icon: "warning",
        text: "Are you sure?",
        showCancelButton: true,
        confirmButtonText: "Delete",
      })
      .then(async (res) => {
        if (res.isConfirmed) {
          try {
            await del(id);
            mySwal.fire({
              text: "Testimonial deleted.",
              icon: "success",
              timer: 2000,
            });
            let test = testimonials.find((x) => x.id === id);
            if (test) {
              let index = testimonials.indexOf(test);
              testimonials.splice(index, 1);
              setTestimonials([...testimonials]);
            }
          } catch (error) {
            HandleException(error);
          }
        }
      });
  };

  return (
    <>
      <section className="content-header"></section>
      <section className="content">
        <div className="row">
          <div className="col-md-4">
            <div className="card card-outline card-danger">
              <div className="card-header with-border">
                <h4 className="card-title">Add New Testimonial</h4>
              </div>
              <div className="card-body">
                <FormGroup>
                  <label>Name</label>
                  <input
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    type="text"
                    className="form-control"
                  />
                </FormGroup>
                <FormGroup className="mt-3">
                  <label>Message</label>
                  <textarea
                    className="form-control"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    rows={5}
                  />
                </FormGroup>
              </div>
              <div className="card-footer text-right">
                <button
                  onClick={() => addNew()}
                  type="button"
                  className="btn btn-danger btn-flat"
                >
                  Add
                </button>
              </div>
              {loading ? (
                <div className="overlay">
                  <i className="fa fa-spinner fa-spin"></i>
                </div>
              ) : undefined}
            </div>
          </div>
          <div className="col-md-8">
            <div className="card card-outline card-danger">
              <div className="card-header with-border">
                <h4 className="card-title">Testimonials</h4>
              </div>
              <div className="card-body">
                <div className="row">
                  {testimonials.map((t) => (
                    <div className="col-sm-6 col-md-4 d-flex">
                      <div className="card d-flex flex-fill">
                        <div className="card-header with-border text-center">
                          {t.name}
                        </div>
                        <div className="card-body">{t.message}</div>
                        <div className="card-footer text-right">
                          <button
                            onClick={() => remove(t.id)}
                            type="button"
                            className="btn btn-danger btn-sm btn-flat"
                          >
                            <i className="fa fa-trash"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {loading ? (
                <div className="overlay">
                  <i className="fa fa-spinner fa-spin"></i>
                </div>
              ) : undefined}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
