import { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import AddColor from "../../components/color/add-color";
import UpdateColor from "../../components/color/update-color";
import { mySwal } from "../../config/sw-config";
import { HandleException } from "../../helpers/exception-handler";
import { useAppDispatch, useAppSelector } from "../../hooks/store-hooks";
import { fetch, remove } from "../../slices/color-slice";
import { IColor } from "../../types/color-type";

export function Colors() {
  const colors = useAppSelector((s) => s.color.colors);
  const dispatch = useAppDispatch();

  const [visibleAddColor, setVisibleAddColor] = useState(false);
  const [colorToUpdate, setColorToUpdate] = useState<IColor | null>(null);

  //on mount
  useEffect(() => {
    async function loadCategories() {
      try {
        await dispatch(fetch()).unwrap();
      } catch (error) {
        HandleException(error);
      }
    }
    loadCategories();
  }, [dispatch]);

  return (
    <>
      <section className="content-header"></section>
      <section className="content">
        <div className="container-fluid">
          <div className="card card-outline card-danger">
            <div className="card-header with-border">
              <h4 className="card-title">Colors</h4>
              <div className="card-tools">
                <Button
                  onClick={() => {
                    setVisibleAddColor(true);
                  }}
                  size="sm"
                >
                  Add New
                </Button>
              </div>
            </div>
            <div className="card-body">
              <Table>
                <thead>
                  <tr>
                    <th>Color</th>
                  </tr>
                </thead>
                <tbody>
                  {colors.map((c, i) => (
                    <tr key={i}>
                      <td>{c.colorName}</td>
                      <td>
                        <Button
                          className="m-2 btn-flat"
                          onClick={() => {
                            setColorToUpdate(c);
                          }}
                          title="Edit"
                        >
                          <i className="fa  fa-edit" />
                        </Button>
                        <Button
                          variant="danger"
                          className="btn-flat"
                          title="Delete"
                          onClick={() => {
                            mySwal
                              .fire({
                                titleText: "Confirm",
                                text: "All products related to this color will  also be removed. Are you sure?",
                                icon: "warning",
                                confirmButtonText: "Delete",
                                cancelButtonText: "Cancel",
                                showCancelButton: true,
                              })
                              .then(
                                async (result) => {
                                  if (result.isConfirmed) {
                                    await dispatch(remove(c.id));
                                    mySwal.fire({
                                      text: "Color Deleted.",
                                      icon: "success",
                                      timer: 1000,
                                      toast: true,
                                      position: "top-right",
                                    });
                                  }
                                },
                                () => {}
                              );
                          }}
                        >
                          <i className="fa  fa-trash" />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </section>
      <AddColor
        visible={visibleAddColor}
        onClose={() => {
          setVisibleAddColor(false);
        }}
        onAdded={() => {
          setVisibleAddColor(false);
        }}
      />

      {colorToUpdate ? (
        <UpdateColor
          color={colorToUpdate!}
          visible={true}
          onClose={() => {
            setColorToUpdate(null);
          }}
          onUpdated={() => {
            setColorToUpdate(null);
          }}
        />
      ) : undefined}
    </>
  );
}
