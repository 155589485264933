import { IFinishType, IFinishTypeCreatePayload, IFinishTypeUpdatePayload } from "../types/finish-type-type";
import * as base from "./base-api-service";

/**
 * Get list of finish types
 */
export const getAll: () => Promise<IFinishType[]> = () => {  
  return base.get("finishtype/getall");
};

/**
 * Get finish type by id
 * @param id finishtype id
 * @returns FinishType
 */
export const get: (id: number) => Promise<IFinishType> = (id: number) => {
  return base.get("finishtype/get", {
    id,
  });
};

/**
 * Add new FinishType
 * @param data Payload
 * @returns FinishType
 */
export const add: (data: IFinishTypeCreatePayload) => Promise<IFinishType> = (
  data: IFinishTypeCreatePayload
) => {
  return base.post("finishtype/add", data);
};

/**
 * Update FinishType
 * @param data Payload
 * @returns FinishType
 */
export const update: (data: IFinishTypeUpdatePayload) => Promise<IFinishType> = (
  data: IFinishTypeUpdatePayload
) => {
  return base.patch("finishtype/update", data);
};

/**
 * Delete FinishType
 * @param id FinishType Id
 * @returns void
 */
export const del: (id: number) => Promise<void> = (id: number) => {
  return base.del("finishtype/delete", {
    id,
  });
};
