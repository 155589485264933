import { IEmailSubscription } from "../types/email-subscription-type";
import * as base from "./base-api-service";

/**
 * Get all subscription
 */
export const getAll: () => Promise<IEmailSubscription[]> = () => {
  return base.get("emailsubscription/getall");
};

/**
 * Delete subscription
 * @param email Email
 * @returns void
 */
export const del: (email: string) => Promise<void> = (email: string) => {
  return base.del("emailsubscription/delete", {
    email
  });
};
