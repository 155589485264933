import { mySwal } from "../config/sw-config";

export function HandleException(exception: any) {
  if(exception.message){
    mySwal.fire({
      text: exception.message,
      icon: "error",
      titleText: "Error",

    });
  }
}
