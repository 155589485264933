import React, { useState } from "react";
import { NavLink } from "react-router-dom";

export function Sidebar() {
  return (
    <aside className="main-sidebar elevation-1 sidebar-light-navy">
      <a href="" className="brand-link">
        <img
          src={process.env.PUBLIC_URL + "/logo192.png"}
          alt="Logo"
          className="brand-image img-circle elevation-3"
          style={{ opacity: 0.8 }}
        />
        <span className="brand-text font-weight-light">Angira Art</span>
      </a>

      <div className="sidebar">
        <nav className="mt-2">
          <ul
            className="nav nav-pills nav-sidebar flex-column nav-flat"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            <li className="nav-header">Menu</li>

            <TreeMenu icon="fa-list" title="Master">
              <li className="nav-item">
                <NavLink to="categories" className="nav-link">
                  <i className="nav-icon fa fa-circle"></i>
                  <p>Categories</p>
                </NavLink>
              </li>
              {/*<li className="nav-item">
                <NavLink className="nav-link" to="colors">
                  <i className="nav-icon fa  fa-circle" />
                  <p>Colors</p>
                </NavLink>
  </li>*/}
              <li className="nav-item">
                <NavLink className="nav-link" to="finish-type">
                  <i className="nav-icon fa  fa-circle" />
                  <p>Finish</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="material-type">
                  <i className="nav-icon fa  fa-circle" />
                  <p>Material</p>
                </NavLink>
              </li>
            </TreeMenu>
            <li className="nav-item">
              <NavLink to="products" className="nav-link">
                <i className="nav-icon fa fa-clipboard-list"></i>
                <p>Products</p>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="collections" className="nav-link">
                <i className="nav-icon fa fa-layer-group"></i>
                <p>Collections</p>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="quotations" className="nav-link">
                <i className="nav-icon fas fa-receipt"></i>
                <p>Quotation</p>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="email-subscriptions" className="nav-link">
                <i className="nav-icon fa fa-envelope"></i>
                <p>Email Subscriptions</p>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="enquiries" className="nav-link">
                <i className="nav-icon fas fa-clipboard-list"></i>
                <p>Enquiries</p>
              </NavLink>
            </li>

            <TreeMenu icon="fa-globe" title="Site Management">
              <li className="nav-item">
                <NavLink to="slider-banners" className="nav-link">
                  <i className="nav-icon fa fa-circle"></i>
                  <p>Home Slider Banner</p>
                </NavLink>
                <NavLink to="featured-products" className="nav-link">
                  <i className="nav-icon fa fa-circle"></i>
                  <p>Featured Products</p>
                </NavLink>
                <NavLink to="aboutus" className="nav-link">
                  <i className="nav-icon fa fa-circle"></i>
                  <p>About Us</p>
                </NavLink>
                <NavLink to="team-members" className="nav-link">
                  <i className="nav-icon fa fa-circle"></i>
                  <p>Team Members</p>
                </NavLink>
                <NavLink to="testimonials" className="nav-link">
                  <i className="nav-icon fa fa-circle"></i>
                  <p>Testimonials</p>
                </NavLink>
                <NavLink to="manage-address" className="nav-link">
                  <i className="nav-icon fa fa-circle"></i>
                  <p>Address</p>
                </NavLink>
                <NavLink to="manage-timing" className="nav-link">
                  <i className="nav-icon fa fa-circle"></i>
                  <p>Timing</p>
                </NavLink>
              </li>
            </TreeMenu>
          </ul>
        </nav>
      </div>
    </aside>
  );
}

type TTreeMenuProps = {
  title: string;
  icon: string;
};

function TreeMenu(props: React.PropsWithChildren<TTreeMenuProps>) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <li className={isOpen ? "nav-item menu-open" : "nav-item"}>
      <a
        href="#"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        className="nav-link"
      >
        <i className={"nav-icon fas " + props.icon}></i>
        <p>
          {props.title}
          <i className="fas fa-angle-left right"></i>
        </p>
      </a>
      <ul className="nav nav-treeview">{props.children}</ul>
    </li>
  );
}
