export function Dashboard() {
    return (
        <>
            <section className="content-header">Dashboard</section>
            <section className="content text-center">
                Some very helpful data will be here...
            </section>
        </>
    );
}
