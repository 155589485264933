import { IFeaturedProduct, IFeaturedProductAddPayload } from "../types/featured-product-type";
import * as base from "./base-api-service";

/**
 * Get list of featured product
 */
export const getAll: () => Promise<IFeaturedProduct[]> = () => {  
  return base.get("site/getfeaturedproducts");
};

/**
 * Add to featured list
 * @param data Payload
 * @returns FeaturedProduct
 */
export const add: (data: IFeaturedProductAddPayload) => Promise<IFeaturedProduct> = (
  data: IFeaturedProductAddPayload
) => {
  return base.post("site/addfeaturedproduct", data);
};

/**
 * Delete from featured list
 * @param id  Id
 * @returns void
 */
export const del: (id: number) => Promise<void> = (id: number) => {
  return base.del("site/deletefeaturedproduct", {
    id,
  });
};
