import "./styles.css";

import { useContext, useEffect, useRef } from "react";
import { useState } from "react";
import { HandleException } from "../../helpers/exception-handler";
import { useAppDispatch, useAppSelector } from "../../hooks/store-hooks";
import { Form } from "react-bootstrap";
import { mySwal } from "../../config/sw-config";
import {
  addMedia,
  fetch,
  removeMedia,
  updateMedia,
} from "../../slices/product-slice";
import { EProductMediaType, IProductMedia } from "../../types/product-type";
import { NavLink, useParams } from "react-router-dom";
import { appContext } from "../../context/app-context";

export default function ProductMedia() {
  const { getImage, getVideo } = useContext(appContext);

  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const product = useAppSelector((s) =>
    s.product.products.find((x) => x.id === +id!)
  );
  //#region form fields
  const [files, setFiles] = useState<FileList>();
  const [type, setType] = useState<EProductMediaType>(EProductMediaType.Photo);
  const [isPublic, settIsPublic] = useState(false);
  const [isMain, setIsMain] = useState(false);
  const [filters, setFilters] = useState<"image/*" | "video/*">("image/*");
  //#endregion

  const [cardHeight, setCardHeight] = useState<number>(0);

  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    async function loadProduct() {
      try {
        await dispatch(fetch()).unwrap();
      } catch (error) {
        HandleException(error);
      }
    }
    if (!product) {
      loadProduct();
    }
  }, [dispatch]);

  //submit form
  const submit = async () => {
    if (isValid()) {
      setLoading(true);
      try {
        await dispatch(
          addMedia({
            files: files!,
            type: type!,
            isPublic,
            isMain: type == EProductMediaType.Video ? false : isMain,
            productId: product!.id,
          })
        ).unwrap();
        mySwal.fire({
          titleText: "Added",
          text: "Product Added.",
          icon: "success",
          timer: 2000,
          toast: true,
        });
        reset();
      } catch (error) {
        HandleException(error);
      } finally {
        setLoading(false);
      }
    }
  };

  //reset form
  const reset = () => {
    setFiles(undefined);
    fileInputRef.current!.value = "";
  };

  //validate form
  const isValid: () => boolean = () => {
    if (!files || files.length <= 0) {
      mySwal.fire({
        text: "Please select file/s.",
        toast: true,
        icon: "error",
        timer: 2000,
        timerProgressBar: true,
      });
      return false;
    }

    if (!type) {
      mySwal.fire({
        text: "Please select type.",
        toast: true,
        icon: "error",
        focusConfirm: true,
        timer: 2000,
        timerProgressBar: true,
      });
      return false;
    }
    return true;
  };

  //delete media
  const deleteMedia = (media: IProductMedia) => {
    mySwal
      .fire({
        titleText: "Confirm",
        text: "Are you sure?",
        showCancelButton: true,
        confirmButtonText: "Yes, Delete!",
        icon: "warning",
      })
      .then(async (res) => {
        if (res.isConfirmed) {
          try {
            await dispatch(removeMedia(media)).unwrap();
          } catch (error) {
            HandleException(error);
          }
        }
      });
  };

  //toggle media public flag
  const update = async (media: IProductMedia) => {
    try {
      await dispatch(
        updateMedia({
          isMain: media.isMain,
          isPublic: media.isPublic,
          id: media.id,
          productId: media.productId,
        })
      ).unwrap();
    } catch (error) {
      HandleException(error);
    }
  };

  return (
    <>
      <section className="content-header">
        <NavLink to="../products" className="btn btn-flat btn-sm btn-warning">
          <i className="fa fa-arrow-left"></i> Back To List
        </NavLink>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-md-4">
            <div className="card card-outline card-danger">
              <div className="card-header with-border">
                <h4 className="card-title">Add New</h4>
              </div>
              <div className="card-body">
                Product: {product?.name}
                <hr />
                <div className="form-group">
                  <label>Type</label>
                  <select
                    value={type}
                    onChange={(e) => {
                      let t = e.target.value as any as EProductMediaType;
                      if (t == EProductMediaType.Photo) {
                        console.log("photo");
                        setFilters("image/*");
                      } else if (t == EProductMediaType.Video) {
                        setFilters("video/*");
                      } else {
                        setFilters("image/*");
                      }
                      setType(e.target.value as any as EProductMediaType);
                    }}
                    className="form-control"
                  >
                    <option value={undefined}>--- Select Type---</option>
                    {Object.keys(EProductMediaType).map((cat, i) => {
                      if (isNaN(cat as any)) {
                        return (
                          <option
                            key={i}
                            value={(EProductMediaType as any)[cat]}
                          >
                            {cat}
                          </option>
                        );
                      }
                      return undefined;
                    })}
                  </select>
                </div>
                <div className="form-group">
                  <label>
                    Files{" "}
                    <small className="text-danger">
                      Please try to keep images in 1:1 ratio.(Video 16:9)
                    </small>
                  </label>
                  <input
                    accept={filters}
                    multiple={filters === "image/*"}
                    disabled={!filters}
                    type="file"
                    ref={fileInputRef}
                    className="form-control"
                    onChange={(e) => {
                      if (e.target.files?.length) {
                        setFiles(e.target.files);
                      }
                    }}
                  />
                </div>
              </div>
              <div className="card-footer text-right">
                <div className="row">
                  <div className="col">
                    <Form.Check
                      className="d-inline"
                      type="switch"
                      label="Public"
                      checked={isPublic}
                      onChange={(e) => {
                        settIsPublic(e.target.checked);
                      }}
                    />
                  </div>
                  <div className="col">
                    <Form.Check
                      disabled={type == EProductMediaType.Video}
                      checked={isMain}
                      className="d-inline"
                      type="switch"
                      label="Primary"
                      onChange={(e) => {
                        setIsMain(e.target.checked);
                      }}
                    />
                  </div>
                  <div className="col">
                    <button
                      type="button"
                      onClick={() => {
                        submit();
                      }}
                      className="btn btn-danger btn-flat"
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
              {loading ? (
                <div className="overlay">
                  <i className="fa fa-spinner fa-spin"></i>
                </div>
              ) : undefined}
            </div>
          </div>
          <div className="col-md-8">
            <div className="card card-outline card-danger">
              <div className="card-header with-border">
                <h4 className="card-title">Product Media Files</h4>
              </div>
              <div className="card-body">
                <div>
                  <label>Photos</label>
                  <hr />
                  <div className="row">
                    {product?.medias
                      .filter((x) => x.type == EProductMediaType.Photo)
                      ?.map((m, i) => (
                        <div
                          key={i}
                          className="col-md-4 col-sm-6 mb-4"
                          style={{
                            height: cardHeight,
                          }}
                          onLoad={(e) => {
                            let w = e.currentTarget.clientWidth;
                            setCardHeight(w);
                          }}
                        >
                          <div className="w-100 h-100 card">
                            <img
                              style={{
                                objectFit: "contain",
                                objectPosition: "center",
                              }}
                              className="w-100 h-100"
                              src={getImage!(m.thumbFile)}
                              alt=""
                            />
                            <div className="media-item-button-container">
                              <div className="row">
                                <div className="col text-center">
                                  <div>Primary</div>
                                  <Form.Check
                                    className="d-inline"
                                    type="switch"
                                    checked={m.isMain}
                                    onChange={(e) => {
                                      update({
                                        ...m,
                                        isMain: e.target.checked,
                                      });
                                    }}
                                  />
                                </div>
                                <div className="col">
                                  <div>Public</div>
                                  <Form.Check
                                    className="d-inline"
                                    type="switch"
                                    checked={m.isPublic}
                                    onChange={(e) => {
                                      update({
                                        ...m,
                                        isPublic: e.target.checked,
                                      });
                                    }}
                                  />
                                </div>
                                <div className="col text-right">
                                  <button
                                    onClick={() => {
                                      deleteMedia(m);
                                    }}
                                    className="btn btn-sm btn-danger btn-flat"
                                  >
                                    <i className="fa fa-trash"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                <hr />
                <div>
                  <label>Videos</label>
                  <hr />
                  <div className="row">
                    {product?.medias
                      .filter((x) => x.type == EProductMediaType.Video)
                      ?.map((m, i) => (
                        <div key={i} className="col-md-4 mb-4 mt-2 col-sm-6">
                          <div className="card">
                            <div className=".media-item-video-button-container">
                              <div className="row">
                                <div className="col text-center">
                                  <div>Public</div>
                                  <Form.Check
                                    className="d-inline"
                                    type="switch"
                                    checked={m.isPublic}
                                    onChange={(e) => {
                                      update({
                                        ...m,
                                        isPublic: e.target.checked,
                                      });
                                    }}
                                  />
                                </div>
                                <div className="col text-right">
                                  <button
                                    onClick={() => {
                                      deleteMedia(m);
                                    }}
                                    className="btn btn-sm btn-danger btn-flat"
                                  >
                                    <i className="fa fa-trash"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="embed-responsive embed-responsive-16by9">
                              <video controls src={getVideo!(m.file)}></video>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
