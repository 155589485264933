import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./slices/auth-slice";
import categorySlice from "./slices/category-slice";
import collectionSlice from "./slices/collection-slice";
import colorSlice  from "./slices/color-slice";
import finishTypeSlice from "./slices/finish-type-slice";
import materialTypeSlice from "./slices/material-type-slice";
import productSlice from "./slices/product-slice";




const store = configureStore({
  reducer: {
    category: categorySlice,
    color: colorSlice,
    finishType: finishTypeSlice,
    materialType: materialTypeSlice,
    product: productSlice,
    collection: collectionSlice,
    auth: authSlice
  },
  middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
          serializableCheck: false,
      }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
