import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/store-hooks";
import { login, logout, userInfo } from "../../slices/auth-slice";

export function Login() {
    const navigate = useNavigate();
    const user = useAppSelector(userInfo);
    const dispatch = useAppDispatch();
    const [pageLoading, setPageLoading] = useState<boolean>(true);
    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");

    useEffect(() => {
        document.body.className = "";
        document.body.className = "hold-transition login-page";
        setPageLoading(false);
        if (user?.token) {
            dispatch(logout());
        }

        return () => {
            document.body.className = "";
        };
    }, []);

    const formSubmit = async () => {
        setError("");
        if (username && password) {
            try {
                setLoading(true);
                await dispatch(
                    login({
                        username,
                        password,
                    })
                ).unwrap();
                navigate("/");
            } catch (error) {
                setPassword("");
                setError("Invalid username or password.");
            } finally {
                setLoading(false);
            }
        }
    };

    return pageLoading ? (
        <div></div>
    ) : (
        <div className="login-box">
            <div className="login-logo">
                <a href="">Angira Art Exports</a>
            </div>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    formSubmit();
                }}
            >
                <div className="card">
                    <div className="card-body login-card-body">
                        <p className="login-box-msg">
                            Sign in to start your session
                        </p>
                        <div className="input-group mb-3">
                            <input
                                type="text"
                                required
                                value={username}
                                onChange={(e) => {
                                    setUsername(e.target.value);
                                    setError("");
                                }}
                                className="form-control"
                                placeholder="Username"
                            />
                            <div className="input-group-append">
                                <div className="input-group-text">
                                    <span className="fas fa-envelope"></span>
                                </div>
                            </div>
                        </div>
                        <div className="input-group mb-3">
                            <input
                                required
                                value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                    setError("");
                                }}
                                type="password"
                                className="form-control"
                                placeholder="Password"
                            />

                            <div className="input-group-append">
                                <div className="input-group-text">
                                    <span className="fas fa-lock"></span>
                                </div>
                            </div>
                        </div>

                        <div className="form-group mb-3">
                            <span className="text-danger">{error}</span>
                        </div>
                        {/*
            <p className="mb-1">
                <a href="forgot-password.html">I forgot my password</a>
            </p>
            */}
                    </div>
                    <div className="card-footer text-right">
                        <input
                            type="submit"
                            className="btn btn-primary btn-flat"
                            value="Submit"
                        />
                    </div>
                    {loading ? (
                        <div className="overlay">
                            <i className="fa fa-spinner fa-spin"></i>
                        </div>
                    ) : undefined}
                </div>
            </form>
        </div>
    );
}
