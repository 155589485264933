import { ITeamMember, ITeamMemberAddPayload, ITeamMemberUpdatePayload } from "../types/team-member-type";
import * as base from "./base-api-service";

/**
 * Get list of Team members
 */
export const getAll: () => Promise<ITeamMember[]> = () => {  
  return base.get("site/getteammembers");
};

/**
 * Get member by id
 * @param id member id
 * @returns TeamMember
 */
export const get: (id: number) => Promise<ITeamMember> = (id: number) => {
  return base.get("site/getteammember", {
    id,
  });
};

/**
 * Add new member
 * @param data Payload
 * @returns TeamMember
 */
export const add: (data: ITeamMemberAddPayload) => Promise<ITeamMember> = (
  data: ITeamMemberAddPayload
) => {
  let fd = new FormData();
  for (let key in data as any) {
    fd.append(key, (data as any)[key]);
  }
  return base.post("site/addteammember", fd);
};

/**
 * Update team member
 * @param data Payload
 * @returns TeamMember
 */
export const update: (data: ITeamMemberUpdatePayload) => Promise<ITeamMember> = (
  data: ITeamMemberUpdatePayload
) => {
  let fd = new FormData();
  for (let key in data as any) {
    fd.append(key, (data as any)[key]);
  }
  return base.patch("site/updateteammember", fd);
};

/**
 * Delete member
 * @param id Member Id
 */
export const del: (id: number) => Promise<void> = (id: number) => {
  return base.del("site/deleteteammember", {
    id,
  });
};
