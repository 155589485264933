import { useContext, useEffect, useState } from "react";
import { FormGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { del, getAll } from "../../AAL/team-members-api-service";
import { mySwal } from "../../config/sw-config";
import { appContext } from "../../context/app-context";
import { HandleException } from "../../helpers/exception-handler";
import { ITeamMember } from "../../types/team-member-type";

export default function TeamMembers() {
  const { getImage } = useContext(appContext);
  const [members, setMembers] = useState<ITeamMember[]>([]);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);
        let ms = await getAll();
        if (ms?.length) {
          setMembers(ms);
        }
      } catch (error) {
        HandleException(error);
      } finally {
        setLoading(false);
      }
    }
    loadData();
  }, []);

  //delete
  const deleteMember = (id: number) => {
    mySwal
      .fire({
        titleText: "Confirm",
        text: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Delete",
      })
      .then(async (res) => {
        if (res.isConfirmed) {
          try {
            await del(id);
            mySwal.fire({
              text: "Member deleted successfully.",
              icon: "success",
              timer: 2000,
              toast: true,
            });
            let item = members.find((x) => x.id === id);
            if (item) {
              let index = members.indexOf(item);
              if (index > -1) {
                members.splice(index, 1);
                setMembers([...members]);
              }
            }
          } catch (error) {}
        }
      });
  };
  return (
    <>
      <section className="content-header"> </section>
      <section className="content">
        <div className="card card-outline card-danger">
          <div className="card-header with-border">
            <h4 className="card-title">Manage Team Members</h4>
            <div className="card-tools">
              <Link
                to="../add-member"
                className="btn btn-default btn-flat btn-sm"
              >
                <i className="fa fa-plus"></i> Add New
              </Link>
            </div>
          </div>
          <div className="card-body">
            <FormGroup>
              <input
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                type="search"
                className="form-control"
                placeholder="Search"
              />
            </FormGroup>
            <table className="table">
              <thead>
                <tr>
                  <th></th>
                  <th>Name</th>
                  <th>Designation</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {members
                  ?.filter(
                    (x) =>
                      x.name.toLowerCase().includes(searchText.toLowerCase()) ||
                      x.designation
                        .toLowerCase()
                        .includes(searchText.toLowerCase())
                  )
                  ?.map((m, i) => (
                    <tr key={i}>
                      <td>
                        <img
                          src={getImage!(m.photo)}
                          style={{ height: "120px", width: "80" }}
                          alt=""
                        />
                      </td>
                      <td>{m.name}</td>
                      <td>{m.designation}</td>
                      <td>
                        <Link
                          to={"../edit-member/" + m.id}
                          className="btn btn-info btn-flat btn-sm"
                          title="Edit"
                        >
                          <i className="fa fa-edit"></i>
                        </Link>
                        <button
                          onClick={() => deleteMember(m.id)}
                          className="btn btn-danger btn-flat btn-sm ml-2"
                          title="Delete"
                        >
                          <i className="fa fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          {loading ? (
            <div className="overlay">
              <i className="fa fa-spinner fa-spin"></i>
            </div>
          ) : undefined}
        </div>
      </section>
    </>
  );
}
