import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import * as ColorAPI from "../AAL/color-api-service";
import { IColor, IColorCreatePayload, IColorUpdatePayload } from "../types/color-type";

// Define a type for the slice state
interface CategoryState {
  colors: IColor[];
}

// Define the initial state using that type
const initialState: CategoryState = {
  colors: [],
};

//#region
//Get All colors
const fetch = createAsyncThunk("color/fetch", async () => {
  let data = await ColorAPI.getAll();
  return data;
});

//Add color
const add = createAsyncThunk(
  "color/add",
  async (payload: IColorCreatePayload, thunkAPI) => {
    let data = await ColorAPI.add(payload);
    return data;
  }
);

//Update color
const update = createAsyncThunk(
  "color/update",
  async (payload: IColorUpdatePayload, thunkAPI) => {
    let data = await ColorAPI.update(payload);
    return data;
  }
);

//remove color
const remove = createAsyncThunk(
  "color/remove",
  async (payload: number, thunkAPI) => {
    await ColorAPI.del(payload);
    return payload;
  }
);
//#endregion

export const colorSlice = createSlice({
  name: "color",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetch.fulfilled, (s, a) => {
        s.colors = a.payload;
      })
      .addCase(add.fulfilled, (s, a) => {
        s.colors.push(a.payload);
      })
      .addCase(update.fulfilled, (s, a) => {
        let c = s.colors.find((x) => x.id === a.payload.id);
        if (c) {
          c.colorName = a.payload.colorName;
        }
      })
      .addCase(remove.fulfilled, (s,a)=> {
        let cat = s.colors.find(x=> x.id === a.payload);
        if(cat){
          let index = s.colors.indexOf(cat);
          if(index>-1){
            s.colors.splice(index,1);
          }
        }
      })
  },
});

export { update, add, fetch, remove };
export default colorSlice.reducer;
