import { useEffect, useState } from "react";
import { FormGroup } from "react-bootstrap";
import { del, getAll } from "../../AAL/email-subscription-api-service";
import { mySwal } from "../../config/sw-config";
import { HandleException } from "../../helpers/exception-handler";
import { IEmailSubscription } from "../../types/email-subscription-type";

export default function EmailSubscriptions() {
  const [list, setList] = useState<IEmailSubscription[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);
        let data = await getAll();
        setList(data);
      } catch (error) {
        HandleException(error);
      } finally {
        setLoading(false);
      }
    }
    loadData();
  }, []);

  //Delete
  const deleteSubscription = (email: string) => {
    mySwal
      .fire({
        titleText: "Confirm",
        text: "Are you sure?",
        showCancelButton: true,
        icon: "warning",
        confirmButtonText: "Delete",
      })
      .then(async (res) => {
        if (res.isConfirmed) {
          setLoading(true);
          try {
            await del(email);
            let sub = list?.find((s) => s.email === email);
            console.log(sub);
            if (sub) {
              let indx = list?.indexOf(sub);
              console.log("index", indx);
              if (indx > -1) {
                list?.splice(indx, 1);
                console.log("List", list);
                setList([...list]);
              }
            }

            mySwal.fire({
              text: "Subscription Deleted.",
              icon: "success",
              timer: 2000,
              toast: true,
            });
          } catch (error) {
            HandleException(error);
          } finally {
            setLoading(false);
          }
        }
      });
  };

  return (
    <>
      <section className="content-header"></section>
      <section className="content">
        <div className="card card-outline card-danger">
          <div className="card-header with-border">
            <h4 className="card-title">Email Subscriptions</h4>
          </div>
          <div className="card-body">
            <FormGroup>
              <input
                type="search"
                placeholder="Search"
                className="form-control"
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
              />
            </FormGroup>
            <table className="table">
              <thead>
                <tr>
                  <th>Email</th>
                  <th>Active</th>
                  <th>Date Subscribe</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {list
                  ?.filter((s) =>
                    s.email.toLowerCase().includes(searchText.toLowerCase())
                  )
                  .map((sub, i) => (
                    <tr key={i}>
                      <td>{sub.email}</td>
                      <td>{sub.isActive ? "Active" : "Inactive"}</td>
                      <td>{sub.dateCreated.toString()}</td>
                      <td>
                        <button
                          onClick={() => {
                            deleteSubscription(sub.email);
                          }}
                          className="btn btn-danger btn-flat btn-sm"
                          title="Delete"
                        >
                          <i className="fa fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          {loading ? (
            <div className="overlay">
              <i className="fa fa-spinner fa-spin"></i>
            </div>
          ) : undefined}
        </div>
      </section>
    </>
  );
}
