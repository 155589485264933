import { IEnquiry, IEnquiryUpdatePayload } from "../types/enquiry-type";
import * as base from "./base-api-service";

/**
 * Get list of enquiries
 */
export const getAll: () => Promise<IEnquiry[]> = () => {  
  return base.get("enquiry/getall");
};

/**
 * Get enquiry by id
 * @param id enquiry id
 * @returns Enquiry
 */
export const get: (id: number) => Promise<IEnquiry> = (id: number) => {
  return base.get("enquiry/get", {
    id,
  });
};

/**
 * update
 * @param data Update Enquiry Payload 
 */
export const update: (data: IEnquiryUpdatePayload) => Promise<void> = (
  data: IEnquiryUpdatePayload
) => {  
  return base.patch("enquiry/update", data);
};

/**
 * Delete enquiry
 * @param id Enquiry Id
 * @returns void
 */
export const del: (id: number) => Promise<void> = (id: number) => {
  return base.del("enquiry/delete", {
    id,
  });
};
