import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import * as CollectionAPI from "../AAL/collection-api-service";
import { ICollection, ICollectionCreatePayload, ICollectionToggleProduct, ICollectionUpdatePayload } from "../types/collection-type";

// Define a type for the slice state
type TState = {
  collections: ICollection[];
}

// Define the initial state using that type
const initialState: TState = {
  collections: [],
};

//#region
//Get All Collections
const fetch = createAsyncThunk("collection/fetch", async () => {
  let data = await CollectionAPI.getAll();
  return data;
});

//Add Collection
const add = createAsyncThunk(
  "collection/add",
  async (payload: ICollectionCreatePayload, thunkAPI) => {
    let data = await CollectionAPI.add(payload);
    return data;
  }
);

//Update Collection
const update = createAsyncThunk(
  "collection/update",
  async (payload: ICollectionUpdatePayload, thunkAPI) => {
    let data = await CollectionAPI.update(payload);
    return data;
  }
);

//delete collection
const remove = createAsyncThunk(
  "collection/remove",
  async (payload: number, thunkAPI) => {
    await CollectionAPI.del(payload);
    return payload;
  }
);

//add product to  collection
const addProduct = createAsyncThunk(
  "collection/addProduct",
  async (payload: ICollectionToggleProduct, thunkAPI) => {
    let data = await CollectionAPI.addToCollection(payload);
    return {prd : data, payload};
  }
);


//remove product to  collection
const removeProduct = createAsyncThunk(
  "collection/removeProduct",
  async (payload: ICollectionToggleProduct, thunkAPI) => {
    await CollectionAPI.removeFromCollection(payload);
    return payload;
  }
);
//#endregion

export const collectionSlice = createSlice({
  name: "collection",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetch.fulfilled, (s, a) => {
        s.collections = a.payload;
      })
      .addCase(add.fulfilled, (s, a) => {
        s.collections.push(a.payload);
      })
      .addCase(update.fulfilled, (s, a) => {
        let c = s.collections.find((x) => x.id === a.payload.id);
        if (c) {
          Object.assign(c, a.payload);
        }
      })
      .addCase(remove.fulfilled, (s,a)=> {
        let cat = s.collections.find(x=> x.id === a.payload);
        if(cat){
          let index = s.collections.indexOf(cat);
          if(index>-1){
            s.collections.splice(index,1);
          }
        }
      })
      .addCase(addProduct.fulfilled, (s,a)=> {
        let coll = s.collections.find(x=> x.id   === a.payload.payload.collectionId);
        if(coll){
          coll.products.push(a.payload.prd);
        }
      })
      .addCase(removeProduct.fulfilled, (s,a)=> {
        let coll = s.collections.find(x=> x.id   === a.payload.collectionId);
        if(coll){
          let prd = coll.products.find(x=> x.id === a.payload.productId);
          if(prd){
            let index = coll.products.indexOf(prd);
            if(index>-1){
              coll.products.splice(index,1);
            }
          }
        }
      })
  },
});

export { update, add, fetch, remove, addProduct, removeProduct };
export default collectionSlice.reducer;
