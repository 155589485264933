import { IProduct, IProductCreatePayload, IProductMedia, IProductMediaAddPayload, IProductMediaUpdatePayload, IProductUpdateFlagPayload, IProductUpdatePayload } from "../types/product-type";
import * as base from "./base-api-service";

/**
 * Get list of products
 */
export const getAll: () => Promise<IProduct[]> = () => {  
  return base.get("product/getall");
};

/**
 * Get product  by id
 * @param id Product id
 * @returns Product
 */
export const get: (id: number) => Promise<IProduct> = (id: number) => {
  return base.get("product/get", {
    id,
  });
};

/**
 * Add new Product
 * @param data Payload
 * @returns Product
 */
export const add: (data: IProductCreatePayload) => Promise<IProduct> = (
  data: IProductCreatePayload
) => {
  return base.post("product/add", data);
};

/**
 * Update Product
 * @param data Payload
 * @returns Product
 */
export const update: (data: IProductUpdatePayload) => Promise<IProduct> = (
  data: IProductUpdatePayload
) => {
  return base.patch("product/update", data);
};

/**
 * Update Product
 * @param data Payload
 * @returns Product
 */
 export const updateFlag: (data: IProductUpdateFlagPayload) => Promise<void> = (
  data: IProductUpdateFlagPayload
) => {
  return base.patch("product/flagupdate", data);
};

/**
 * Update Product media
 * @param data Payload
 * @returns ProductMedia
 */
 export const updateMedia: (data: IProductMediaUpdatePayload) => Promise<IProductMedia> = (
  data: IProductMediaUpdatePayload
) => {
  return base.patch("product/updatemedia", data);
};

/**
 * Delete Product
 * @param id Product Id
 * @returns void
 */
export const del: (id: number) => Promise<void> = (id: number) => {
  return base.del("product/delete", {
    id,
  });
};

/**
 * Add new Product Media
 * @param data Payload
 * @returns ProductMedia
 */
 export const addMedia: (data: IProductMediaAddPayload) => Promise<IProductMedia[]> = (
  data: IProductMediaAddPayload
) => {
  var fd = new FormData();
  fd.append("isMain",data.isMain as any);
  fd.append("isPublic", data.isPublic as any);
  fd.append("productId", data.productId as any)
  fd.append("type", data.type as any);
  for(let i = 0 ;i< data.files.length; i++){
    fd.append(`files`,data.files[0])
  }
  return base.post("product/addmedia", fd);
};

/**
 * Delete Product Media
 * @param id ProductMedia Id
 * @returns void
 */
 export const deleteMedia: (id: number) => Promise<void> = (id: number) => {
  return base.del("product/deletemedia", {
    id,
  });
};