import { useEffect, useState } from "react";
import { FormGroup } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { del, get, getAll, update } from "../../AAL/enquiry-api-service";
import { mySwal } from "../../config/sw-config";
import { HandleException } from "../../helpers/exception-handler";
import { IEnquiry } from "../../types/enquiry-type";

export default function ViewEnquiry() {
  const { id } = useParams();
  const [enquiry, setEnquiry] = useState<IEnquiry>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadData() {
      if (id) {
        try {
          setLoading(true);
          let enq = await get(+id);
          setEnquiry(enq);
        } catch (error) {
          HandleException(error);
        } finally {
          setLoading(false);
        }
        try {
          await update({
            id: +id,
            resolved: true,
          });
        } catch (error) {
          HandleException(error);
        }
      }
    }
    loadData();
  }, [id]);

  return (
    <>
      <section className="content-header">
        <Link to="../enquiries" className="btn btn-warning btn-flat btn-sm">
          <i className="fa  fa-arrow-left"></i> Back To List
        </Link>
      </section>
      <section className="content">
        <div className="card card-outline card-danger">
          <div className="card-header with-border">
            <h4 className="card-title">Enquiry</h4>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-6">
                <FormGroup>
                  <label>Name</label>
                  <input
                    type={"text"}
                    className="form-control"
                    value={enquiry?.name}
                    readOnly
                  />
                </FormGroup>
              </div>
              <div className="col-md-6">
                <FormGroup>
                  <label>Email</label>
                  <input
                    type={"text"}
                    className="form-control"
                    value={enquiry?.email}
                    readOnly
                  />
                </FormGroup>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <FormGroup className="mt-3">
                  <label>Date</label>
                  <input
                    type={"text"}
                    className="form-control"
                    value={enquiry?.dateCreated.toDateString()}
                    readOnly
                  />
                </FormGroup>
              </div>
              <div className="col-md-6">
                <FormGroup className="mt-3">
                  <label>Date Seen</label>
                  <input
                    type={"text"}
                    className="form-control"
                    value={enquiry?.dateSeen?.toString()}
                    readOnly
                  />
                </FormGroup>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <FormGroup className="mt-3">
                  <label>Subject</label>
                  <input
                    type={"text"}
                    className="form-control"
                    value={enquiry?.subject}
                    readOnly
                  />
                </FormGroup>
              </div>
              <div className="col-md-6">
                <FormGroup className="mt-3">
                  <label>Message</label>
                  <textarea
                    rows={10}
                    className="form-control"
                    value={enquiry?.message}
                    readOnly
                  ></textarea>
                </FormGroup>
              </div>
            </div>

            <hr/>
            <h4>Selected Products</h4>
            <table className="table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Quantity</th>
                </tr>

              </thead>
              <tbody>
                {
                  enquiry?.cartItems?.map(item=> <tr key={item.id}>
                    <td>{item.productName}</td>
                    <td>{item.quantity}</td>
                  </tr>)
                }
              </tbody>
            </table>
          </div>
          {loading ? (
            <div className="overlay">
              <i className="fa fa-spinner fa-spin"></i>
            </div>
          ) : undefined}
        </div>
      </section>
    </>
  );
}
