import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getAll } from "../../AAL/product-api-service";
import {
  addItemToQuotation,
  deleteQuotationItem,
  getQuotation,
} from "../../AAL/quotation-api-service";
import { mySwal } from "../../config/sw-config";
import { HandleException } from "../../helpers/exception-handler";
import { IProduct } from "../../types/product-type";
import { IQuotation, IQuotationItem } from "../../types/quotation-type";

export function ManageQuotationItems() {
  const { id } = useParams();
  const [searchProductStr, setSearchProductStr] = useState("");
  const [searchProductStrQ, setSearchProductStrQ] = useState("");
  const [products, setProducts] = useState<IProduct[]>([]);

  const [quotation, setQuotation] = useState<IQuotation>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function loadData() {
      try {
        let res = await getAll();
        let quot = await getQuotation(Number(id));
        setProducts(res);
        setQuotation(quot);
      } catch (error) {
        HandleException(error);
      } finally {
        setLoading(false);
      }
    }
    loadData();
  }, [id]);

  const addItem = async (item: IProduct) => {
    try {
      let state = await mySwal.fire({
        title: "Price",
        input: "number",
        inputValue: item.price,
        showCloseButton: true,
        inputPlaceholder: "Please enter price...",
        inputValidator: (inputValue) => {
          if (!inputValue) {
            return "Please enter price.";
          }
          return null;
        },
      });
      if (state.value) {
        try {
          setLoading(true);
          await addItemToQuotation({
            productId: item.id,
            quotationId: quotation!.id,
            price: state.value,
          });
          var q = await getQuotation(Number(id));
          setQuotation(q);
        } catch (error) {
          HandleException(error);
        } finally {
          setLoading(false);
        }
      }
    } catch (error) {
      HandleException(error);
    }
  };

  const removeItem = async (item: IQuotationItem) => {
    try {
      setLoading(true);
      await deleteQuotationItem(item.id);
      var q = await getQuotation(Number(id));
      setQuotation(q);
    } catch (error) {
      HandleException(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <section className="content-header">
        <Link to="../quotations" className="btn btn-flat btn-warning">
          <i className="fa fa-arrow-left"></i> Back To List
        </Link>
      </section>
      <section className="content">
        <div className="card card-outline card-danger">
          <div className="card-header">
            <h4 className="card-title">Manage Quotation Items</h4>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>Party Name</label>
                  <input
                    value={quotation?.to}
                    readOnly
                    type="text"
                    className="form-control"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>Email</label>
                  <input
                    type="email"
                    className="form-control"
                    value={quotation?.email}
                    readOnly
                  />
                </div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-md-6">
                <div className="card">
                  <div className="card-body">
                    <p>Products</p>
                    <div>
                      <input
                        value={searchProductStr}
                        onChange={(e) => {
                          setSearchProductStr(e.target.value);
                        }}
                        type="search"
                        className="form-control"
                        placeholder="Search"
                      />
                    </div>
                    <div
                      style={{
                        height: "500px",
                        overflowX: "scroll",
                        overflowY: "auto",
                      }}
                    >
                      <table className="table mt-3">
                        <thead>
                          <tr>
                            <th>Product Code</th>
                            <th>Name</th>
                            <th>Price</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {products
                            ?.filter(
                              (p) =>
                                (p.code
                                  .toLowerCase()
                                  .includes(searchProductStr.toLowerCase()) ||
                                  p.name
                                    .toLowerCase()
                                    .includes(
                                      searchProductStr.toLowerCase()
                                    )) &&
                                !quotation?.items.find(
                                  (x) => x.product.id === p.id
                                )
                            )
                            ?.map((p, i) => (
                              <tr key={i}>
                                <td>{p.code}</td>
                                <td>{p.name}</td>
                                <td>{p.price}</td>
                                <td>
                                  <button
                                    onClick={() => addItem(p)}
                                    className="btn btn-flat btn-sm btn-primary"
                                  >
                                    <i className="fa fa-plus"></i>
                                  </button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="card">
                  <div className="card-body">
                    <p>Products in list</p>
                    <div>
                      <input
                        value={searchProductStrQ}
                        onChange={(e) => {
                          setSearchProductStrQ(e.target.value);
                        }}
                        type="search"
                        className="form-control"
                        placeholder="Search"
                      />
                    </div>
                    <div
                      style={{
                        height: "500px",
                        overflowX: "scroll",
                        overflowY: "auto",
                      }}
                    >
                      <table className="table mt-3">
                        <thead>
                          <tr>
                            <th>Product Code</th>
                            <th>Name</th>
                            <th>Price</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {quotation?.items
                            ?.filter(
                              (p) =>
                                p.product.name
                                  .toLowerCase()
                                  .includes(searchProductStrQ.toLowerCase()) ||
                                p.product.code
                                  .toLowerCase()
                                  .includes(searchProductStrQ.toLowerCase())
                            )
                            ?.map((p, i) => (
                              <tr key={i}>
                                <td>{p.product.code}</td>
                                <td>{p.product.name}</td>
                                <td>{p.price}</td>
                                <td>
                                  <button
                                    onClick={() => {
                                      removeItem(p);
                                    }}
                                    className="btn btn-flat btn-sm btn-danger"
                                  >
                                    <i className="fa fa-trash"></i>
                                  </button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {loading ? (
            <div className="overlay">
              <i className="fa fa-spinner fa-spin"></i>
            </div>
          ) : undefined}
        </div>
      </section>
    </>
  );
}
