import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { Footer } from "../../components/footer/footer";
import { Navbar } from "../../components/navbar/navbar";
import { Sidebar } from "../../components/sidebar/sidebar";

export function Admin() {
  const [preloader, setPreloader] = useState<boolean>(true);

  useEffect(() => {
    document.body.className =
      "sidebar-mini layout-navbar-fixed layout-fixed layout-footer-fixed";
    setPreloader(false);
    return () => {
      document.body.className = "";
    };
  }, []);

  return (
    <div className="wrapper">
      {preloader ? (
        <div className="preloader flex-column justify-content-center align-items-center">
          <img
            className="animation__shake"
            src="dist/img/AdminLTELogo.png"
            alt="AdminLTELogo"
            height="60"
            width="60"
          />
        </div>
      ) : undefined}

      <Navbar />
      <Sidebar />

      <div className="content-wrapper">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}
